import { useContext, useState } from 'react';
import stock from '../images/upup.png';
import withdraw from '../images/withdraw.png';
import plus from '../images/cancel.png';
import load from '../images/load.gif';
import failed from '../images/fail.png';
import OverviewStock from './overviewStock';
import StockAnalysis from './stockAnalysis';
import StockWidget from './stockWidget';
import { UserInfo, WalletAddyData } from '../contexts/pageContext';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc} from "firebase/firestore";
import copy from '../images/copy.png';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import emailjs from '@emailjs/browser';
import btcImg from '../images/btc.png';
import ethImg from '../images/ethqr.png';
import dogeImg from '../images/DogeQR.png';
import usdtImg from '../images/usdtQR.png';

const StockDash = () => {

    const [userData, setUserData] = useContext(UserInfo);
    const [walletAddy, setWalletAddy] = useContext(WalletAddyData);
    const [depoPopup, setDepoPopup ] = useState(false);
    const [ineliPopup, setIneliPopup] = useState(false);
    const [loading, setLoading] = useState('No');
    const [wdLoading, setWdLoading] = useState('No');
    const [withdrawPopup, setWithdrawPopup] = useState(false);

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage(app);

    //date and time
    // For todays date;
    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }
    // For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    const newDate = new Date();
    const date =  newDate.today();
    const time = newDate.timeNow();

    const handleAmount = (e)=>{
        const newAmount = e.target.value;
        setAmount(newAmount);
    } 

    function handleChange(event) {
        setFile(event.target.files[0]);
    };

    //document data
    const userName = userData.name;
    const userUid = userData.uid;
    const transType = 'deposit';
    const transState = 'Pending';
    const [file, setFile] = useState(null);
    const [amount, setAmount] = useState('0');
    const imageName = userName + date;

    // handle email sends
    const serviceID = 'service_pxrmptj';
    const templateID = 'template_h4zpzix';
    const userMail = userData.mail;


    const sendEmail = () =>{
        emailjs.send(serviceID, templateID,{
            to_name: userName,
            Amount: '$' + (amount),
            send_to: userMail,
        }, 'vUXoQ-0-B0fTk0W-_')
        .then((result) => {
            console.log(result.text);
        },
        (error) => {
            console.log(error.text);
        });
    };
     
    const sumbmitImg = ()=>{
        setLoading('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName,
        };
        const storageRef = ref(storage, imageName, metadata);

        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                const transactionDoc = collection(db, 'transactions');
                const docData = {
                    name: userName,
                    uid:userUid,
                    transactionType:transType,
                    transactionState:transState,
                    tag:'Stocks',
                    amount:amount,
                    date:date,
                    url:url,
                    time:time,
                }
                addDoc(transactionDoc, docData)
                .then(()=>{
                    setLoading('No');
                    sendEmail();
                    const confirm = document.getElementById('confirm');
                    confirm.style.display = 'block';
                });
            });
        });
    };
    const [wdAmount, setWdAmount] = useState('');
    const submitWithdraw = ()=>{
        const transactionDoc = collection(db, 'transactions');
        const docData = {
            name: userName,
            uid:userUid,
            transactionType:'withdrawal',
            transactionState:transState,
            tag:'Stocks',
            amount: wdAmount,
            date:date,
            time:time,
        }
        addDoc(transactionDoc, docData)
        .then(()=>{

        })
    };
    function withdrawalMethod(){
        const btcBlock = document.getElementById('btcMeth');
        const ppBlock = document.getElementById('ppMeth');
        const wuBlock = document.getElementById('wuMeth');
        const payMethod = document.getElementById('payMethod').value;
        if(payMethod === 'btc'){
            btcBlock.style.display = 'block';
            ppBlock.style.display = 'none';
            wuBlock.style.display = 'none';
        }
        else if(payMethod === 'pal'){
            btcBlock.style.display = 'none';
            ppBlock.style.display = 'block';
            wuBlock.style.display = 'none';
        }
        else if(payMethod === 'wu'){
            btcBlock.style.display = 'none';
            ppBlock.style.display = 'none';
            wuBlock.style.display = 'block';
        }
    };

    //check deposit balance
    const btcBalCheck = ()=>{
        const btcWarn = document.getElementById('btcWarn');
        setWdLoading('Yes')
        if(userData.StockDeposit === '0'){
            setWdLoading('No');
            btcWarn.style.display = 'block';
        }
        else if(userData.StockDeposit !== '0' && userData.StocksUpgradeFee == 'false'){
            setIneliPopup(true);
            setStatus('upgrade');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.StockDeposit !== '0' && userData.StocksUpgradeFee !== 'false' && userData.StocksWithdrawFee == 'false'){
            setIneliPopup(true);
            setStatus('withdraw');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.StockDeposit !== '0' && userData.StocksUpgradeFee !== 'false' && userData.StocksWithdrawFee !== 'false' && userData.StocksCommisionFee == 'false'){
            setIneliPopup(true);
            setStatus('commission');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.StockDeposit !== '0' && userData.StocksUpgradeFee !== 'false' && userData.StocksWithdrawFee !== 'false' && userData.StocksCommisionFee !== 'false'){
            setIneliPopup(false);
            setWdLoading('No');
            submitWithdraw();
            alert('Recieved, We are processing your Request')
        }
    };      

    const handleCopyClick = (e) =>{
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
        addy.select();
        addy.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(addy.value);
    }
   
    const [status, setStatus] = useState('');

    const [qrPopup, setQrPopup] = useState(false);
    const [ Qrbtc, setQrbtc] = useState(false);
    const [ Qreth, setQreth] = useState(false);
    const [ Qrdoge, setQrdoge] = useState(false);
    const [ Qrusdt, setQrusdt] = useState(false);

    const getBtcQr = ()=>{
        setQrPopup(true);
        setQrbtc(true);
        setQreth(false);
        setQrdoge(false);
        setQrusdt(false);
    };
    const getEthQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(true);
        setQrdoge(false);
        setQrusdt(false);
    };
    const getDogeQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(false);
        setQrdoge(true);
        setQrusdt(false);
    };
    const getUsdtQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(false);
        setQrdoge(false);
        setQrusdt(true);
    };

    return ( 
        <div>
            {ineliPopup && <div className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.8)] fixed top-0 left-0 flex justify-center items-center px-5 z-[1000]">
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setIneliPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className="py-8 px-5 space-y-3 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                        <img src={ failed } className=" w-10 h-10" alt="" />
                        <p className=" font-Anuphan text-base font-medium text-[#a7a7a7]">We are Sorry, You are Ineligible to Withdraw at this Time.</p>
                        {status === 'upgrade' && <p className="font-Anuphan text-sm font-medium text-[#fff]">Kindly Upgrade your Account to Continue</p>}
                        { status === 'withdraw' && <p className="font-Anuphan text-sm font-medium text-[#fff]">You need to Pay a Withdrawal Fee</p>}
                        { status === 'commission' && <p className="font-Anuphan text-sm font-medium text-[#fff]">You need to Pay a commision Fee</p>}
                        <p className="font-Anuphan text-sm font-medium text-[#a7a7a7] capitalize">Contact our live support for more information</p>
                    </div>
                </div>
                </div>}
            {/* { qrPopup && <div className="  w-[100%] h-[100vh] z-[9999999999999] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5 overflow-y-auto">
                <div className=" w-full md:w-[400px] h-[400px] bg-[#1e1e1e] flex justify-center items-center relative">
                    <img src={plus} onClick={()=>{setQrPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    { Qrbtc && <img src= { btcImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qreth && <img src= { ethImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qrdoge && <img src= { dogeImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qrusdt && <img src= { usdtImg } className=" w-[300px] h-[300px]" alt="" />}
                </div>
                </div>} */}
            { depoPopup && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5 overflow-y-auto'>
                <div className=' h-[90vh]'>
                        <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                            <img src={plus} onClick={()=>{setDepoPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                            <div className=" py-8 px-5 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                        <p className=" font-Anuphan text-base font-medium">Send Your Deposit to One Of The Accounts Below</p>
                        <div className=" space-y-3 w-full md:w-[450px] flex flex-col justify-center items-center mt-3">
                            <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Bitcoin Address
                                <input type="text" readOnly value={walletAddy.btc} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] text-xs md:text-sm active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                                <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                                <button onClick={ getBtcQr } id="btc" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Btc Qr Code</button> 
                            </label>
                            <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Ethereum Address
                                <input type="text" readOnly value={walletAddy.eth} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] text-xs md:text-sm active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                                <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                                <button onClick={ getEthQr } id="Eth" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Eth Qr Code</button> 
                            </label>
                            <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Doge Address
                                <input type="text" readOnly value={walletAddy.doge} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] text-xs md:text-sm active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                                <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                                <button onClick={ getDogeQr } id="doge" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Doge Qr Code</button> 
                            </label>
                            <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                USDT TRC
                                <input type="text" readOnly value={walletAddy.usdt} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] text-xs md:text-sm active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                                <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                                <button onClick={ getUsdtQr } id="Usdt" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Usdt Qr Code</button> 
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input type="text" onKeyUp={ handleAmount } className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Upload Proof of Payment
                                <input type="file" onChange={ handleChange } className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px]  focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                <p className='font-Anuphan text-xs'>For your convenience, we offer bank transfer as a payment option. To obtain further details, please reach out to our live customer support.</p>
                            </span>
                            <p id='confirm' className='hidden mr-auto font-Anuphan text-xs text-green-600 capitalize font-medium'>*Your transaction has been initiated but is awaiting confirmation</p>
                            <button onClick={ sumbmitImg } className=" block ml-auto px-2 rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                                { loading === 'No' && 'Submit'}
                                { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                            </button>
                        </div>
                    </div>
                    </div>
                        </div>
                    </div>}
                    { withdrawPopup && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5'>
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setWithdrawPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className="py-8 px-5 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                        <p className="font-Anuphan text-base font-medium">Select Your Preferred Withdrawal Method</p>
                        <div className=" w-full flex flex-col font-Anuphan justify-center items-center text-sm font-normal mt-3 text-[rgba(255,255,255,0.8)]">
                            <select name="" onChange={ withdrawalMethod } id="payMethod" type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" >
                                <option value="">Select Withdrawal Method</option>
                                <option value="btc">Bitcoin(Recommended)</option>
                                <option value="pal">Paypal</option>
                                <option value="wu">Western Union</option>
                            </select>
                        </div>
                        <div id="btcMeth" className=" mt-3 w-full md:w-[450px] space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input type="text" onChange={(e)=>{setWdAmount(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Wallet Address
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                        </div>
                        <div id="ppMeth" className=" mt-3 w-full md:w-[450px] space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input type="text" onChange={(e)=>{setWdAmount(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Paypal Mail/Address
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>    
                        </div>
                        <div id="wuMeth" className=" mt-3 w-full md:w-[450px] space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input type="text" onChange={(e)=>{setWdAmount(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Account Number
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                           
                        </div>
                        <p id="btcWarn" className=" md:ml-[52px] mr-auto mt-2 font-Anuphan text-xs font-medium text-red-600 hidden">Insufficient Balance</p>
                        <button onClick={ btcBalCheck } className="px-2 md:mr-[52px] mt-3 block ml-auto rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                            { wdLoading === 'No' && 'Submit'}
                            { wdLoading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                    </div>
                </div>
            </div>}
            <div className="absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
                <div className="relative space-y-6  lg:space-y-0">
                    <div className=' flex flex-col md:flex-row justify-between space-y-6 md:space-y-0'>
                        <div className=" w-full lg:w-[500px] h-[200px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-5 flex flex-col relative">
                            <span className=" flex flex-row items-center space-x-2">
                                <button className="flex justify-center items-center w-[40px] h-[40px] bg-[#2A49F9] rounded-xl shadow-md">
                                    <img src={ stock } alt="" />
                                </button>
                                <p className=' font-Anuphan text-sm text-[#fff] font-medium'>Stocks</p>
                            </span>
                            <div className=" flex flex-row justify-between">
                                <span className=' flex flex-col mt-2'>
                                    <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Total Balance</p>
                                    <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.StockTotalbal+'.00'}</p>
                                </span>
                                <div className=" flex justify-end items-end space-x-5">
                                    <span className="text-center flex flex-col items-center">
                                    <button onClick={()=>{setDepoPopup(true)}} className=" w-[40px] h-[40px] rounded-[50%] bg-[rgba(219,224,255,0.2)] backdrop-blur-[15px] flex justify-center items-center">
                                        <img src={ withdraw } className=" rotate-180" alt="deposit" />
                                    </button>
                                    <p className=" font-Anuphan text-xs text-[#a7a7a7] font-medium">Deposit</p>
                                    </span>
                                    <span className=" text-center flex flex-col items-center">
                                    <button onClick={()=>{setWithdrawPopup(true)}} className=" w-[40px] h-[40px] rounded-[50%] bg-[rgba(219,224,255,0.2)] backdrop-blur-[15px] flex justify-center items-center">
                                        <img src={ withdraw } alt="withdraw" />
                                    </button>
                                    <p className=" font-Anuphan text-xs text-[#a7a7a7] font-medium">Withdraw</p>
                                    </span>
                                </div>
                            </div>
                            <div className=' absolute bottom-5 flex flex-row w-full justify-between pr-9'>
                                <span className=' flex flex-col'>
                                    <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Deposit</p>
                                    <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.StockDeposit+'.00'}</p>
                                </span>
                                <span className=' flex flex-col'>
                                    <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Profits</p>
                                    <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.StockProfits+'.00'}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="analysis" className=" lg:absolute right-0 top-[440px] w-full lg:w-[450px] h-[120px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col overflow-hidden">
                        <StockWidget/>
                    </div>
                    <div id="analysis" className=" lg:absolute right-0 top-0 w-full lg:w-[450px] h-[420px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col overflow-hidden">
                        <StockAnalysis/>
                    </div>
                    <div className=" lg:absolute left-0 top-[230px] w-full lg:w-[500px] h-[330px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col overflow-hidden">
                        <OverviewStock/>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default StockDash;