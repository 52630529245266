import { useContext, useState } from 'react';
import stock from '../images/upup.png';
import load from '../images/load.gif';
import withdraw from '../images/withdraw.png';
import { FeatWdPopup, UserInfo } from '../contexts/pageContext';
const FeatBal = () => {
    const [userData, setUserData] = useContext(UserInfo);
    const [wdPopup, setWdPopup] = useContext(FeatWdPopup);
    return ( 
        <div>
            <div className=" w-full  h-[200px] bg-[#1e1e1e] mt-2 rounded-md shadow-md p-5 flex flex-col relative">
                <span className=" flex flex-row items-center space-x-2">
                    <button className="flex justify-center items-center w-[40px] h-[40px] bg-[#2A49F9] rounded-xl shadow-md">
                        <img src={ stock } alt="" />
                    </button>
                    <p className=' font-Anuphan text-sm text-[#fff] font-medium'>Featured</p>
                </span>
                <div className=" flex flex-row justify-between">
                    <span className=' flex flex-col mt-2'>
                        <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Total Balance</p>
                        <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.FeatTotalbal+'.00'}</p>
                    </span>
                    <div className=" flex justify-end items-end">
                        <span className=" text-center flex flex-col items-center">
                            <button onClick={()=>{setWdPopup(true)}} className=" w-[40px] h-[40px] rounded-[50%] bg-[rgba(219,224,255,0.2)] backdrop-blur-[15px] flex justify-center items-center">
                                <img src={ withdraw } alt="withdraw" />
                            </button>
                            <p className=" font-Anuphan text-xs text-[#a7a7a7] font-medium">Withdraw</p>
                        </span>
                    </div>
                </div>
                <div className=' absolute bottom-5 flex flex-row w-full justify-between pr-9'>
                    <span className=' flex flex-col'>
                        <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Deposit</p>
                        <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.FeatDeposit+'.00'}</p>
                    </span>
                    <span className=' flex flex-col'>
                        <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Profits</p>
                        <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.FeatProfits+'.00'}</p>
                    </span>
                </div>
            </div>
        </div>
     );
}
 
export default FeatBal;