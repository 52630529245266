import load from '../images/load.gif';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useContext, useState } from 'react';
import { UserInfo, WalletAddyData } from '../contexts/pageContext';
import copy from '../images/copy.png';
import btcImg from '../images/btc.png';
import plus from '../images/cancel.png';
import ethImg from '../images/ethqr.png';
import dogeImg from '../images/DogeQR.png';
import usdtImg from '../images/usdtQR.png';

const Upgrade = () => {

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage(app);

    const [userData, setUserData] = useContext(UserInfo);
    const [walletAddy, setWalletAddy] = useContext(WalletAddyData);
    const [loading, setLoading] = useState('No');

    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }
    // For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    const newDate = new Date();
    const date =  newDate.today();
    const time = newDate.timeNow();

    const handleAmount = (e)=>{
        const newAmount = e.target.value;
        setAmount(newAmount);
    } 

    function handleChange(event) {
        setFile(event.target.files[0]);
    };

    //document data
    const userName = userData.name;
    const userUid = userData.uid;
    const transType = 'upgrade';
    const transState = 'Pending';
    const [file, setFile] = useState(null);
    const [amount, setAmount] = useState('0');
    const imageName = userName + date;
     
    const sumbmitImg = ()=>{
        setLoading('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName,
        };
        const storageRef = ref(storage, imageName, metadata);

        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                const transactionDoc = collection(db, 'transactions');
                const docData = {
                    name: userName,
                    uid:userUid,
                    transactionType:transType,
                    transactionState:transState,
                    tag:'Stocks',
                    amount:amount,
                    date:date,
                    url:url,
                    time:time,
                }
                addDoc(transactionDoc, docData)
                .then(()=>{
                    setLoading('No');
                    const confirmit = document.getElementById('confirm2');
                    confirmit.style.display = 'block';
                });
            });
        });
    };
    const handleCopyClick = (e) =>{
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
        addy.select();
        addy.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(addy.value);
    }


    const [qrPopup, setQrPopup] = useState(false);
    const [ Qrbtc, setQrbtc] = useState(false);
    const [ Qreth, setQreth] = useState(false);
    const [ Qrdoge, setQrdoge] = useState(false);
    const [ Qrusdt, setQrusdt] = useState(false);

    const getBtcQr = ()=>{
        setQrPopup(true);
        setQrbtc(true);
        setQreth(false);
        setQrdoge(false);
        setQrusdt(false);
    };
    const getEthQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(true);
        setQrdoge(false);
        setQrusdt(false);
    };
    const getDogeQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(false);
        setQrdoge(true);
        setQrusdt(false);
    };
    const getUsdtQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(false);
        setQrdoge(false);
        setQrusdt(true);
    };

    return ( 
        <>
        { qrPopup && <div className="  w-[100%] h-[100vh] z-[9999999999999] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5 overflow-y-auto">
                <div className=" w-full md:w-[400px] h-[400px] bg-[#1e1e1e] flex justify-center items-center relative">
                    <img src={plus} onClick={()=>{setQrPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    { Qrbtc && <img src= { btcImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qreth && <img src= { ethImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qrdoge && <img src= { dogeImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qrusdt && <img src= { usdtImg } className=" w-[300px] h-[300px]" alt="" />}
                </div>
            </div>}
        <div className="flex items-center px-5 md:px-[5%] lg:px-[12%] absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] -z-[99]">
            <div className=" border border-[rgba(255,255,255,0.2)] p-5 flex flex-col justify-center items-center w-full">
                <p className=" font-Anuphan text-base font-medium">Choose one of the Wallets Below to upgrade your account</p>
                <div className=" space-y-3 w-full md:w-[450px] flex flex-col justify-center items-center mt-5">
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Bitcoin Address
                        <input type="text" readOnly value={walletAddy.btc} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] md:text-sm text-xs focus:border-0 active:border-0 rounded-md  mt-1"/>
                        <img src={ copy } onClick={handleCopyClick} className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getBtcQr } id="btc" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Btc Qr Code</button>
                    </label>
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Ethereum Address
                        <input type="text" readOnly value={walletAddy.eth} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] md:text-sm text-xs focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={handleCopyClick} className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getEthQr } id="Eth" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Eth Qr Code</button> 
                    </label>
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Doge Address
                        <input type="text" readOnly value={walletAddy.doge} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] md:text-sm text-xs focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={handleCopyClick} className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getDogeQr } id="doge" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Doge Qr Code</button> 
                    </label>
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        USDT TRC
                        <input type="text" readOnly value={walletAddy.usdt} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] md:text-sm text-xs focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={handleCopyClick} className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getUsdtQr } id="Usdt" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Usdt Qr Code</button> 
                    </label>
                    <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Amount
                        <input type="text" onKeyUp={ handleAmount } className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Upload Proof of Payment
                        <input type="file" onChange={ handleChange } className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        <p className='font-Anuphan text-xs'>For your convenience, we offer bank transfer as a payment option. To obtain further details, please reach out to our live customer support.</p>
                    </span>
                    <p id='confirm2' className='hidden mr-auto font-Anuphan text-xs text-green-600 capitalize font-medium'>*Your transaction has been initiated but is awaiting confirmation</p>
                    <button onClick={ sumbmitImg } className=" block ml-auto px-2 rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                        { loading === 'No' && 'Submit'}
                        { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                    </button>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Upgrade;
<>
</>