import { motion } from "framer-motion";

const Testimonial = () => {
    return ( 
        <>
        <motion.div 
        initial={{y:200, opacity:0}}
        whileInView={{y:0, opacity:1}}
        transition={{type:'tween', duration:0.5, delay:0.3}}
        className=" px-5 pb-10 md:px-12 md:pb-16 relative">
            <p className=" text-2xl md:text-3xl font-extrabold text-center font-Anuphan capitalize">What Our clients say about us</p>
            <div className='overflow-x-scroll lg:overflow-x-hidden scrollbar-hide'>
                <div className=" flex flex-row  lg:flex-wrap justify-between space-x-8 lg:space-x-0 mt-11">
                    <div className=" p-5  lg:p-8 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px]">
                        <p className="font-normal font-Anuphan text-sm w-[250px] mb-3">“The customer service at this brokerage is exceptional. Any time I've had a question or concern, they've been quick to respond and provide helpful solutions”</p>
                        <span className=" space-y-1">
                            <p className=" font-normal font-Anuphan text-sm">David Clark</p>
                            <p className=" font-thin font-Anuphan text-sm ">Washington DC, USA</p>
                        </span>
                    </div>
                    <div className=" p-5 lg:p-8 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px] ">
                        <p className="font-normal font-Anuphan text-sm w-[250px] mb-3">“I was hesitant to get into trading, but this brokerage made it easy and accessible. They have a great selection of investment products and their commission rates are very competitive. I've already seen some great returns on my investments and I couldn't be happier with my experience”</p>
                        <span className=" space-y-1">
                            <p className=" font-normal font-Anuphan text-sm">Sarah Lang</p>
                            <p className=" font-thin font-Anuphan text-sm ">Paris, France</p>
                        </span>
                    </div>
                    <div className=" p-5 lg:p-8 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px] ">
                        <p className="font-normal font-Anuphan text-sm w-[250px] mb-3">“. I would recommend them to anyone looking for a trustworthy and effective trading partner”</p>
                        <span className=" space-y-1">
                            <p className=" font-normal font-Anuphan text-sm">James M.</p>
                            <p className=" font-thin font-Anuphan text-sm ">Georgia, USA</p>
                        </span>
                    </div>
                    <div className=" p-5 lg:p-8 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] lg:mt-7 rounded-[20px] ">
                        <p className="font-normal font-Anuphan text-sm w-[250px] mb-3">“I've been trading with this brokerage for years and they've been an invaluable partner in my investment journey. Their experts provide fantastic insights and advice, and their platform is easy to use and intuitive”</p>
                        <span className=" space-y-1">
                            <p className=" font-normal font-Anuphan text-sm">John T.</p>
                            <p className=" font-thin font-Anuphan text-sm ">New York, USA</p>
                        </span>
                    </div>
                    <div className="p-5 lg:p-8 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] lg:mt-7 rounded-[20px] ">
                        <p className="font-normal font-Anuphan text-sm w-[250px] mb-3">“I've been trading with this brokerage for a few months now and I'm already seeing great results. Their platform is intuitive and easy to use, and their customer support team is always there to help me out”</p>
                        <span className=" space-y-1">
                            <p className=" font-normal font-Anuphan text-sm">Cantering Clark</p>
                            <p className=" font-thin font-Anuphan text-sm ">Alabama, USA</p>
                        </span>
                    </div>
                    <div className=" p-5 lg:p-8 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] lg:mt-7 rounded-[20px] ">
                        <p className="font-normal font-Anuphan text-sm w-[250px] mb-3">“I've tried other brokerages in the past, but this one is by far the best. Their selection of investment products is unparalleled”</p>
                        <span className=" space-y-1">
                            <p className=" font-normal font-Anuphan text-sm">Micheal W.</p>
                            <p className=" font-thin font-Anuphan text-sm ">Madrid, Spain</p>
                        </span>
                    </div>
                </div>
            </div>
        </motion.div>
        </>
     );
}
 
export default Testimonial;