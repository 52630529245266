import logo from '../images/logo.png';
import crypto from '../images/crypto.png';
import overview from '../images/overview.png';
import stock from '../images/stock.png';
import forex from '../images/forex.png';
import upgrade from '../images/upgrade.png';
import transaction from '../images/transaction.png';
import logout from '../images/logout.png';
import user from '../images/user.png';
import { useContext, useEffect } from 'react';
import { PageContext } from '../contexts/pageContext';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';



const Sidebardash = () => {

    const Navigate = useNavigate();

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth();
    const [page, setPage] = useContext(PageContext);

    const logOut = ()=>{
        signOut(auth).then(() => {
            Navigate('/Signin')
            }).catch((error) => {
            console.log('An error happened');
        });
    };

    return ( 
        <>
        <div className="">
            <span className=' ml-10 md:ml-0 flex flex-row  items-center space-x-2'>
              <img src={ logo } alt="" />
              <p className=" text-base md:hidden lg:block font-normal font-Bruno">AlgoFlare</p>
            </span>
            <div className=' mt-10 text-[#a7a7a7] text-sm font-Anuphan space-y-5'>
                <button onClick={()=>{setPage('Overview')}} className=' flex flex-row items-center w-full space-x-2 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ overview } alt="" />
                    <p className='md:hidden lg:block'>Overview</p>
                </button>
                <button onClick={()=>{setPage('Crypto')}}  className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ crypto } alt="" />
                    <p className='md:hidden lg:block'>Crypto</p>
                </button>
                <button onClick={()=>{setPage('Forex')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ forex } alt="" />
                    <p className='md:hidden lg:block'>Forex</p>
                </button>
               <button onClick={()=>{setPage('Stocks')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ stock } alt="" />
                    <p className='md:hidden lg:block'>Stocks</p>
                </button>
                <button onClick={()=>{setPage('Transactions')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ transaction } alt="" />
                    <p className='md:hidden lg:block'>Transactions</p>
                </button>
                <button onClick={()=>{setPage('Upgrade')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ upgrade } alt="" />
                    <p className='md:hidden lg:block'>Upgrade</p>
                </button>
                <button onClick={()=>{setPage('Settings')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ user } alt="" />
                    <p className='md:hidden lg:block'>Settings</p>
                </button>
                <button id='logout' onClick={ logOut } className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ logout } alt="" />
                    <p className='md:hidden lg:block'>Logout</p>
                </button>
            </div>
        </div>
        </>
     );
}
 
export default Sidebardash;