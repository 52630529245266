import { useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { PageContext } from "../contexts/pageContext";

const Transactions = () => {
  const [page, setPage] = useContext(PageContext);

  const firebaseConfig = {
    apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
    authDomain: "algoflare.firebaseapp.com",
    projectId: "algoflare",
    storageBucket: "algoflare.appspot.com",
    messagingSenderId: "263253915028",
    appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
    measurementId: "G-5SXDQ31257",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const db = getFirestore(app);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const colRef = collection(db, "transactions");
        const q = query(
          colRef,
          where("uid", "==", uid),
          orderBy("date", "desc")
        );
        onSnapshot(q, (snapshot) => {
          const transactions = [];
          snapshot.docs.forEach((doc) => {
            transactions.push({ id: doc.id, ...doc.data() });
          });
          setTransactionData(transactions);
        });
      } else {
      }
    });
  }, [page, auth, db]);

  function CheckStat() {
    const checkClass = document.querySelectorAll(".check");
    for (let i = 0; i < checkClass.length; i++) {
      if (checkClass[i].innerText === "Completed") {
        checkClass[i].classList.add(
          "py-1",
          "rounded-md",
          "bg-green-700",
          "w-20"
        );
      } else if (checkClass[i].innerText === "Pending") {
        checkClass[i].classList.add(
          "py-1",
          "rounded-md",
          "bg-orange-700",
          "w-20"
        );
      }
    }
  }
  useEffect(() => {
    CheckStat();
  });

  const [transactionData, setTransactionData] = useState([]);

  return (
    <div className=" absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
      <div className="bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 rounded-md shadow overflow-auto">
        <table className=" border-collapse text-center border-b w-full border-[rgba(255,255,255,0.2)] mt-3">
          <thead>
            <tr className=" text-[#a7a7a7] text-sm font-Anuphan font-medium">
              <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">
                Amount
              </th>
              <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">
                Type
              </th>
              <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">
                Date
              </th>
              <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">
                Tag
              </th>
              <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionData.map((doc) => {
              return (
                <tr
                  key={doc.id}
                  className="text-[#a7a7a7] text-sm font-Anuphan font-medium"
                >
                  <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">
                    {"$" + doc.amount}
                  </td>
                  <td className="p-2 border-b capitalize  border-[rgba(255,255,255,0.2)]">
                    {doc.transactionType}
                  </td>
                  <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">
                    {doc.date}
                  </td>
                  <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">
                    {doc.tag}
                  </td>
                  <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">
                    <button className="check">{doc.transactionState}</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transactions;
