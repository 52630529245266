import crypto from '../images/cryptocurrency.png';
import forex from '../images/change.png';
import stock from '../images/upup.png';
import TickerWidget from './tickerTab';
import load from '../images/load.gif';
import failed from '../images/fail.png';
import StocksTab from './stocksTab';
import StocksTable from './stockstable';
import { useContext, useState } from 'react';
import plus from '../images/cancel.png';
import { FeatBuyContext, FeatWdPopup, FeatureCycle,  FeatureInfo,  FeaturePrice,  FeatureProfit,  FeaturedName, UserInfo, WalletAddyData } from "../contexts/pageContext";
import FeatBal from './featBal.';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import emailjs from '@emailjs/browser';
import copy from '../images/copy.png';

const OverviewDash = () => {
    const [featBuy, setFeatBuy] = useContext(FeatBuyContext);
    const [featName, setFeatName] = useContext(FeaturedName);
    const [featCycle, setFeatCycle] = useContext(FeatureCycle);
    const [featPrice, setFeatPrice] = useContext(FeaturePrice);
    const [featProfit, setFeatProfit] =  useContext(FeatureProfit);
    const [featInfo, setFeatInfo] = useContext(FeatureInfo);
    const [userData, setUserData] = useContext(UserInfo);
    const [wdPopup, setWdPopup] = useContext(FeatWdPopup);
    const [walletAddy, setWalletAddy] = useContext(WalletAddyData)
    const [wdLoading, setWdLoading] = useState('No');
    const [ineliPopup, setIneliPopup] = useState(false);
    const [units, setUnits] = useState(0);
    const [depoPopup, setDepoPopup ] = useState(false);
    const strPrice = parseInt(featPrice);
    const [loading, setLoading] = useState('No');
    

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage(app);

    //date and time
    // For todays date;
    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }
    // For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    } 

    function handleChange(event) {
        setFile(event.target.files[0]);
    };

    const newDate = new Date();
    const date =  newDate.today();
    const time = newDate.timeNow();

    //document data
    const userName = userData.name;
    const userUid = userData.uid;
    const transType = 'deposit';
    const transState = 'Pending';
    const [file, setFile] = useState(null);
    const imageName = userName + date;
    
    // handle email sends

    const serviceID = 'service_j8wphoh';
    const templateID = 'template_h4zpzix';
    const userMail = userData.mail; 

    const sendEmail = () =>{
        emailjs.send(serviceID, templateID,{
            to_name: userName,
            Amount: '$' + (units * strPrice),
            send_to: userMail,
        }, 'vUXoQ-0-B0fTk0W-_')
        .then((result) => {
            console.log(result.text);
        },
        (error) => {
            console.log(error.text);
        });
    };


    const sumbmitImg = (e)=>{
        e.preventDefault();
        setLoading('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName,
        };
        const storageRef = ref(storage, imageName, metadata);

        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                const transactionDoc = collection(db, 'transactions');
                const docData = {
                    name: userName,
                    uid:userUid,
                    transactionType:transType,
                    transactionState:transState,
                    tag:'Featured',
                    amount:(units * strPrice),
                    date:date,
                    url:url,
                    time:time,
                }
                addDoc(transactionDoc, docData)
                .then(()=>{
                    setLoading('No')
                    const confirm = document.getElementById('confirm');
                    confirm.style.display = 'block';
                    sendEmail();
                });
            });
        });
    };
    
    const [wdAmount, setWdAmount] = useState('');
    const submitWithdraw = ()=>{
        const transactionDoc = collection(db, 'transactions');
        const docData = {
            name: userName,
            uid:userUid,
            transactionType:'withdrawal',
            transactionState:transState,
            tag:'Featured',
            amount: wdAmount,
            date:date,
            time:time,
        }
        addDoc(transactionDoc, docData)
        .then(()=>{

        })
    };
    
    function withdrawalMethod(){
        const btcBlock = document.getElementById('btcMeth');
        const ppBlock = document.getElementById('ppMeth');
        const wuBlock = document.getElementById('wuMeth');
        const payMethod = document.getElementById('payMethod').value;
        if(payMethod === 'btc'){
            btcBlock.style.display = 'block';
            ppBlock.style.display = 'none';
            wuBlock.style.display = 'none';
        }
        else if(payMethod === 'pal'){
            btcBlock.style.display = 'none';
            ppBlock.style.display = 'block';
            wuBlock.style.display = 'none';
        }
        else if(payMethod === 'wu'){
            btcBlock.style.display = 'none';
            ppBlock.style.display = 'none';
            wuBlock.style.display = 'block';
        }
    };

    //check deposit balance
    const btcBalCheck = ()=>{
        const btcWarn = document.getElementById('btcWarn');
        setWdLoading('Yes')
        if(userData.FeatDeposit === '0'){
            setWdLoading('No');
            btcWarn.style.display = 'block';
        }
        else if(userData.FeatDeposit !== '0' && userData.FeatUpgradeFee == 'false'){
            setIneliPopup(true);
            setStatus('upgrade');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.FeatDeposit !== '0' && userData.FeatUpgradeFee !== 'false' && userData.FeatWithdrawFee == 'false'){
            setIneliPopup(true);
            setStatus('withdraw');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.FeatDeposit !== '0' && userData.FeatUpgradeFee !== 'false' && userData.FeatWithdrawFee !== 'false' && userData.FeatCommisionFee == 'false'){
            setIneliPopup(true);
            setStatus('commission');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.FeatDeposit !== '0' && userData.FeatUpgradeFee !== 'false' && userData.FeatWithdrawFee !== 'false' && userData.FeatCommisionFee !== 'false'){
            setIneliPopup(false);
            setWdLoading('No');
            submitWithdraw();
            alert('Recieved, We are processing your Request')
        }
    };      
   
    const [status, setStatus] = useState('');

    const handleIncrement = () => {
        setUnits((prevNumber) => prevNumber + 1);
    }
    
    const handleDecrement = () => {
        setUnits((prevNumber) => prevNumber - 1)
    }

    const handleCopyClick = (e) =>{
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
        addy.select();
        addy.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(addy.value);
    }
    
    const handleReferClick = (e) =>{
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
        addy.select();
        addy.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(addy.value);
    }
    
    return ( 
        <div>
            {ineliPopup && <div className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.8)] fixed top-0 left-0 flex justify-center items-center px-5 z-[1000]">
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setIneliPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className="py-8 px-5 space-y-3 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                        <img src={ failed } className=" w-10 h-10" alt="" />
                        <p className=" font-Anuphan text-base font-medium text-[#a7a7a7]">We are Sorry, You are Ineligible to Withdraw at this Time.</p>
                        {status === 'upgrade' && <p className="font-Anuphan text-sm font-medium text-[#fff]">Kindly Upgrade your Account to Continue</p>}
                        { status === 'withdraw' && <p className="font-Anuphan text-sm font-medium text-[#fff]">You need to Pay a Withdrawal Fee</p>}
                        { status === 'commission' && <p className="font-Anuphan text-sm font-medium text-[#fff]">You need to Pay a commision Fee</p>}
                        <p className="font-Anuphan text-sm font-medium text-[#a7a7a7] capitalize">Contact our live support for more information</p>
                    </div>
                </div>
                </div>}
            { wdPopup && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5'>
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setWdPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className="py-8 px-5 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                        <p className="font-Anuphan text-base font-medium">Select Your Preferred Withdrawal Method</p>
                        <div className=" w-full flex flex-col font-Anuphan justify-center items-center text-sm font-normal mt-3 text-[rgba(255,255,255,0.8)]">
                            <select name="" onChange={ withdrawalMethod } id="payMethod" type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" >
                                <option value="">Select Withdrawal Method</option>
                                <option value="btc">Bitcoin(Recommended)</option>
                                <option value="pal">Paypal</option>
                                <option value="wu">Western Union</option>
                            </select>
                        </div>
                        <div id="btcMeth" className="w-full md:w-[450px]  mt-3 space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input type="text" onChange={(e)=>{setWdAmount(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Wallet Address
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                        </div>
                        <div id="ppMeth" className=" mt-3 w-full md:w-[450px] space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input type="text" onChange={(e)=>{setWdAmount(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Paypal Mail/Address
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>    
                        </div>
                        <div id="wuMeth" className=" mt-3 w-full md:w-[450px] space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input type="text" onChange={(e)=>{setWdAmount(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Account Number
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                           
                        </div>
                        <p id="btcWarn" className=" md:ml-[52px] mr-auto mt-2 font-Anuphan text-xs font-medium text-red-600 hidden">Insufficient Balance</p>
                        <button onClick={ btcBalCheck } className="px-2 md:mr-[52px] mt-3 block ml-auto rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                            { wdLoading === 'No' && 'Submit'}
                            { wdLoading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                    </div>
                </div>
            </div>}
        { featBuy && <div className=" w-full h-[100vh] fixed z-[10000] px-5 top-0 left-0 bg-[rgba(0,0,0,0.7)] flex justify-center items-center">
            <div className=' w-full md:w-[450px] rounded-md shadow p-5 bg-[#1e1e1e] relative flex justify-center flex-col text-sm font-Anuphan font-medium items-center space-y-3'>
                <img src={plus} onClick={()=>{setFeatBuy(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                <p className=' text-base font-bold'>{featName}</p>
                <p>Price: { '$'+featPrice}</p>
                <p className=' text-green-700'>Profit: {featProfit}</p>
                <p>{featCycle}</p>
                <p className=' h-[90px] text-[#a7a7a7] w-full overflow-y-scroll scrollbar'>{featInfo}</p>
                <label className='w-full  font-Anuphan text-sm font-medium'>
                    How Many Units?
                    <div className='w-full h-[44px] mt-2 rounded-md bg-transparent relative'>
                        <input type="number" value={units} min={0} max={100} step={1} onChange={(e) => setUnits(Number(e.target.value))} readOnly className=' w-full h-[44px] rounded-md bg-transparent border border-[rgba(255,255,255,0.2)] focus:border-[rgba(255,255,255,0.2)] text-center focus-within:border-[rgba(255,255,255,0.2)] focus-visible:border-[rgba(255,255,255,0.2)] active:border-[rgba(255,255,255,0.2)]'/>
                        <button className='absolute top-[12px] font-Anuphan text-base font-medium w-5 h-5 rounded-md bg-white text-black flex items-center justify-center left-3' onClick={handleDecrement}>-</button>
                        <button className='absolute top-[12px] font-Anuphan text-base font-medium w-5 h-5 rounded-md bg-white text-black flex items-center justify-center right-3' onClick={handleIncrement}>+</button>
                    </div>
                </label>
                <label className='w-full'>
                    Amount
                    <input type="number" value={ (units * strPrice)} readOnly className=' mt-2 w-full h-[44px] rounded-md bg-transparent border border-[rgba(255,255,255,0.2)] text-center focus:border-[rgba(255,255,255,0.2)] focus-within:border-[rgba(255,255,255,0.2)] focus-visible:border-[rgba(255,255,255,0.2)] active:border-[rgba(255,255,255,0.2)]' />
                </label>
                <button onClick={()=>{setDepoPopup(true)}} className=' px-2 mt-3 block ml-auto rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]'>Buy</button>
            </div>
        </div> }
        { depoPopup && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center z-[10000] overflow-y-auto px-5'>
            <div className=''>
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setDepoPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className=" py-8 px-5 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                <p className=" font-Anuphan text-base font-medium">Send Your Deposit to One Of The Accounts Below</p>
                <div className=" space-y-3 w-full md:w-[450px] flex flex-col justify-center items-center mt-3">
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Bitcoin Address
                        <input type="text" readOnly value={walletAddy.btc} className=" text-xs md:text-sm w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                    </label>
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Ethereum Address
                        <input type="text" readOnly value={walletAddy.eth} className=" text-xs md:text-sm w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                    </label>
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Doge Address
                        <input type="text" readOnly value={walletAddy.doge} className="text-xs md:text-sm  w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                    </label>
                    <label className="relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        USDT TRC
                        <input type="text" readOnly value={walletAddy.usdt} className="text-xs md:text-sm  w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                    </label>
                    <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Amount
                        <input readOnly value={(units * strPrice)} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Upload Proof of Payment
                        <input onChange={ handleChange } type="file" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px]  focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        <p className='font-Anuphan text-xs'>For your convenience, we offer bank transfer as a payment option. To obtain further details, please reach out to our live customer support.</p>
                    </span>
                    <p id='confirm' className='hidden mr-auto font-Anuphan text-xs text-green-600 capitalize font-medium'>*Your transaction has been initiated but is awaiting confirmation</p>
                    <button type='submit' onClick={ sumbmitImg } className=" block ml-auto px-2 rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                        { loading === 'No' && 'Submit'}
                        { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                    </button>
                </div>
                </div>    
            </div>
                </div>
            </div>}
        <div className=" absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
            <span className=' flex flex-col font-Anuphan text-sm w-full md:w-[310px] font-medium text-[#a7a7a7] relative'>
                Referral Code
                <input type="text" value={'https://bit.ly/43grNQE'} readOnly className='w-full h-[45px] mt-2 p-3 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-md' />
                <button onClick={handleReferClick} className=' px-3 h-[45px] bg-[#2A49F9] text-white absolute right-0 top-[28px] rounded-r-md'>Copy</button>
            </span>
            <div className=' flex flex-col md:flex-row justify-between space-y-6 md:space-y-0'>
                <div className=" w-full md:w-[210px] lg:w-[310px] h-[150px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col relative">
                    <span className=" flex flex-row items-center space-x-2">
                        <button className="flex justify-center items-center w-[40px] h-[40px] bg-[#2A49F9] rounded-xl shadow-md">
                            <img src={ crypto } alt="" />
                        </button>
                        <p className=' font-Anuphan text-sm text-[#fff] font-medium'>Cryptocurency</p>
                    </span>
                    <span className=' flex flex-col mt-2'>
                        <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Total Balance</p>
                        <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.CryptoTotalbal+'.00'}</p>
                    </span>
                    <div className=' absolute bottom-3 flex flex-row w-full justify-between pr-5'>
                        <span className=' flex flex-col'>
                            <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Deposit</p>
                            <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.CryptoDeposit+'.00'}</p>
                        </span>
                        <span className=' flex flex-col'>
                            <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Profits</p>
                            <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.CryptoProfits+'.00'}</p>
                        </span>
                    </div>
                </div>
                <div className=" w-full md:w-[210px] lg:w-[310px] h-[150px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col relative">
                    <span className=" flex flex-row items-center space-x-2">
                        <button className="flex justify-center items-center w-[40px] h-[40px] bg-[#2a49f9] rounded-xl shadow-md">
                            <img src={ forex } alt="" />
                        </button>
                        <p className=' font-Anuphan text-sm text-[#fff] font-medium'>Forex</p>
                    </span>
                    <span className=' flex flex-col mt-2'>
                        <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Total Balance</p>
                        <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.ForexTotalbal+'.00'}</p>
                    </span>
                    <div className=' absolute bottom-3 flex flex-row w-full justify-between pr-5'>
                        <span className=' flex flex-col'>
                            <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Deposit</p>
                            <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.ForexDeposit+'.00'}</p>
                        </span>
                        <span className=' flex flex-col'>
                            <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Profits</p>
                            <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.ForexProfits+'.00'}</p>
                        </span>
                    </div>
                </div>
                <div className=" w-full md:w-[210px] lg:w-[310px] h-[150px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col relative">
                    <span className=" flex flex-row items-center space-x-2">
                        <button className="flex justify-center items-center w-[40px] h-[40px] bg-[#2A49F9] rounded-xl shadow-md">
                            <img src={ stock } alt="" />
                        </button>
                        <p className=' font-Anuphan text-sm text-[#fff] font-medium'>Stocks</p>
                    </span>
                    <span className=' flex flex-col mt-2'>
                        <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Total Balance</p>
                        <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.StockTotalbal+'.00'}</p>
                    </span>
                    <div className=' absolute bottom-3 flex flex-row w-full justify-between pr-5'>
                        <span className=' flex flex-col'>
                            <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Deposit</p>
                            <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.StockDeposit+'.00'}</p>
                        </span>
                        <span className=' flex flex-col'>
                            <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Profits</p>
                            <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.StockProfits+'.00'}</p>
                        </span>
                    </div>
                </div>
            </div>
            <div className=' w-full text-left overflow-x-auto p-5 bg-[rgba(78,78,97,0.2)] rounded-md backdrop-blur-[15px]'>
                <p className=' font-Anuphan text-base text-[#fff] font-bold'>Stake Featured Markets</p>
                <FeatBal/>
                <StocksTable/>
            </div>
            <div className=' w-full h-[80px] bg-[rgba(78,78,97,0.2)] rounded-md backdrop-blur-[15px]'>
                <TickerWidget/>
            </div>
            <div className=' w-full h-[300px] bg-[rgba(78,78,97,0.2)] rounded-md backdrop-blur-[15px]'>
                <StocksTab/>
            </div>
        </div>
        </div>
     );
}
 
export default OverviewDash;