import udl from '../images/udl.png';
import jdl from '../images/jdl.png';
import discount from '../images/discount.png';
import badge from '../images/badge.png';
import heroImg from '../images/hero-img.png';
import btc from '../images/BTC.svg.png';
import graph from '../images/graph.svg';
import eur from '../images/eur.png';
import apple from '../images/apple.png';
import { motion } from 'framer-motion';

const Hero = () => {
    return ( 
        
        <motion.div>
        <img src={ udl } className=' absolute top-0 left-0 -z-[99999] w-[250px] h-[250px] blur-2xl backdrop-blur-xl' alt="" />
        <img src={ jdl } className=' absolute bottom-0 md:-bottom-[7%] right-0 -z-[99999] w-[250px] h-[250px] blur-2xl backdrop-blur-xl' alt="" />
        <div className=' mt-[50px] md:mt-[70px]'>
            <div className=' uni'>
                <div className='belowUni'>
                    <iframe className='frame bg1' src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=" width="100%" height="36px" scrolling="auto"  marginwidth="0" marginheight="0" frameborder="0" border="0" ></iframe>
                </div>
            </div>
        </div>
        <div className=' p-5 md:p-12  z-[9999999] flex flex-col md:flex-row justify-between w-full'>
            <motion.div 
            initial={{ opacity:0}}
            animate={{ opacity:1}}
            transition={{type:'tween', duration:1, delay:0.3}}
            className=' w-full md:w-1/2 space-y-3 md:space-y-5'>
                <span className=' flex space-x-3 mb-5'>
                    <img src={ discount } className=' w-6 h-6' alt="" />
                    <p className=' font-Anuphan text-sm md:text-base font-normal'>Get 10% off upgrade fee for new members</p>
                </span>
                <span className=' text-xl md:text-4xl font-Anuphan font-extrabold'>
                    <p className=' tracking-wide'>The Most <span className=' text-[#2A49F9]'>Efficient</span></p>
                    <p className=' tracking-wide'>Trading and Investment</p>
                    <p className=' tracking-wide'>Platform</p>
                </span>
                <p className=' md:w-[350px] font-Anuphan text-base'>Professional trading system for scanning, analyzing, developing strategies and trading by statistical arbitrage in cryptocurrency, Stocks and Forex markets.</p>
                <button className=' px-5 font-Anuphan py-2 rounded-[15px] bg-[#2A49F9]'>Get Started</button>
                <span className=' flex flex-row items-end -translate-y-[20px] md:-translate-y-[40px] font-Anuphan'>
                    <p className=' text-xl md:text-2xl font-medium border-r pr-2'>2,000+<span className=' font-thin tracking-wide text-sm'> Active Users</span></p>
                    <p className=' text-xl md:text-2xl font-medium pl-2'>10K+<span className=' font-thin tracking-wide text-sm'> Payouts</span></p>
                    <img src={ badge } className=' w-[80px]  md:w-[100px]' alt="" />
                </span>
            </motion.div>
            <div className=' w-full md:w-1/2 flex items-center justify-center'>
                <motion.img
                initial={{ x:1000}}
                animate={{ x:0}}
                transition={{type:'spring', stiffness:'50',delay:0.3}}
                src={ heroImg } className=' z-[30] md:-translate-y-10' alt="" />
            </div>
        </div>
        <div className='pb-8 md:pb-12 w-full flex justify-center items-center px-2 md:px-12  lg:-translate-y-[80px]'>
            <div className=' px-0 py-4 w-full md:px-8 md:py-8 rounded-[30px] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] flex flex-row flex-wrap'>
                <div className=' w-1/3 px-3 md:px-6 space-y-3 border-r'>
                    <div className=' w-full justify-between items-center flex'>
                        <span className=' flex flex-row space-x-3 items-center'>
                            <img src={ btc } className=' h-6 w-6 lg:w-12 lg:h-12 md:w-8 md:h-8' alt="" />
                            <p className=' font-Anuphan font-light text-sm uppercase'>Btc</p>
                        </span>
                        <p className='hidden md:block font-Anuphan font-light text-sm uppercase text-gray-300'>-0.09%</p>
                    </div>
                    <div className=' w-full justify-between items-end flex'>
                        <span className=' flex items-center'>
                            <p className=' font-Anuphan font-light text-sm md:text-base uppercase'>$28k</p>
                        </span>
                        <img src={ graph } className=' w-[50px] md:w-[100px]' alt="" />
                    </div>
                </div>
                <div className=' w-1/3 px-2 md:px-6 space-y-3 '>
                    <div className=' w-full justify-between items-center flex'>
                        <span className=' flex flex-row space-x-3 items-center'>
                            <img src={ eur } className=' h-6 w-6 lg:w-12 lg:h-12 md:w-8 md:h-8' alt="" />
                            <p className=' font-Anuphan font-light text-sm uppercase'>Eur/usd</p>
                        </span>
                        <p className=' hidden md:block font-Anuphan font-light text-sm uppercase text-gray-300'>-0.09%</p>
                    </div>
                    <div className=' w-full justify-between items-end flex'>
                        <span className=' flex items-center'>
                            <p className=' font-Anuphan font-light text-sm md:text-base uppercase'>$28k</p>
                        </span>
                        <img src={ graph } className='w-[50px] md:w-[100px]' alt="" />
                    </div>
                </div>
                <div className=' w-1/3 px-3 md:px-6 space-y-3 border-l'>
                    <div className=' w-full justify-between items-center flex'>
                        <span className=' flex flex-row space-x-3 items-center'>
                            <img src={ apple } className=' h-6 w-6 lg:w-12 lg:h-12 md:w-8 md:h-8' alt="" />
                            <p className=' font-Anuphan font-light text-sm uppercase'>apple</p>
                        </span>
                        <p className=' hidden md:block font-Anuphan font-light text-sm uppercase text-gray-300'>-0.09%</p>
                    </div>
                    <div className=' w-full justify-between items-end flex'>
                        <span className=' flex items-center'>
                            <p className=' font-Anuphan font-light text-sm md:text-base uppercase'>$28k</p>
                        </span>
                        <img src={ graph } className='w-[50px] md:w-[100px]' alt="" />
                    </div>
                </div>
            </div>
        </div>
    </motion.div>
     );
}
 
export default Hero;