const Footer = () => {
  return (
    <>
      <div className=" px-5 pb-10 md:px-12 md:pb-10 pt-5 relative bg-[hsla(221,40%,9%,1)]">
        <div className=" flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between">
          <p className="text-2xl text-[#fff] font-bold font-Anuphan capitalize">
            Bitstakepro
          </p>
          <div className=" flex flex-col space-y-1">
            <p className="text-lg text-[#fff] font-normal font-Anuphan capitalize">
              Company
            </p>
            <p className="text-base text-[#9FA3AB] font-normal font-Anuphan capitalize">
              Home
            </p>
            <p className="text-base text-[#9FA3AB] font-normal font-Anuphan capitalize">
              About
            </p>
            <p className="text-base text-[#9FA3AB] font-normal font-Anuphan capitalize">
              Register
            </p>
            <p className="text-base text-[#9FA3AB] font-normal font-Anuphan capitalize">
              Log-In
            </p>
          </div>
          <div className=" flex flex-col space-y-1">
            <p className="text-lg text-[#fff] font-normal font-Anuphan capitalize">
              Help Center
            </p>
            <p className="text-base text-[#9FA3AB] font-normal font-Anuphan capitalize">
              Support
            </p>
          </div>
          <div className=" flex flex-col space-y-1">
            <p className="text-lg text-[#fff] font-normal font-Anuphan capitalize">
              Legal
            </p>
            <p className="text-base text-[#9FA3AB] font-normal font-Anuphan capitalize">
              Terms & Conditions
            </p>
          </div>
        </div>
        <div className=" space-y-3 mt-8">
          <p className="text-lg text-[#9FA3AB] font-medium font-Anuphan capitalize">
            Disclaimer
          </p>
          <p className="text-base text-[#9FA3AB] font-normal font-Anuphan">
            At Bitstakepro, we believe there is no better advertisement than
            satisfied customers. which is why majority of our new clients are
            affiliates from our existing clients. We are proud that our
            customers like to recommend our financial service to others. Because
            of this, we are pleased to offer one of the strongest online
            affiliate programs in the financial service industry, with a high
            commission rate, customized tools and reports, and timely commission
            payout
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
