import udl from '../images/udl.png';
import jdl from '../images/jdl.png';
import Footer from "../comps/footer";
import Navbar from "../comps/navbar";
import { motion } from 'framer-motion';

const Contact = () => {
    return ( 
        <>
        <Navbar/>
        <motion.div
        initial={{x:100, opacity:0}}
        animate={{x:0, opacity:1}}
        exit={{x:-100, opacity:0}}
        transition={{type:'spring', stiffness:80, duration:0.2}}
        >
        <img src={ udl } className=' absolute  md:block top-0 left-0 -z-[99999] w-[200px] h-[200px] blur-2xl backdrop-blur-xl' alt="" />
        <div className=" mt-[70px] w-full p-5 md:p-10 flex flex-col md:justify-center md:items-center  relative">
            <img src={ jdl } className=' absolute bottom-0 md:-bottom-[7%] right-0 -z-[99999] w-[200px] h-[200px] blur-2xl backdrop-blur-xl' alt="" />
            <p className=' text-lg md:text-2xl font-bold text-center font-Anuphan capitalize"'>Have a Question? Talk To Us</p>
            <div className=' mt-5 flex flex-col space-y-5'>
                <input className='w-full md:w-[400px] rounded-md h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] font-Anuphan text-sm' placeholder='Name' type="text" />
                <input className='w-full md:w-[400px] rounded-md h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] font-Anuphan text-sm' placeholder='Email' type="text" />
                <textarea className='w-full md:w-[400px] h-[250px] rounded-md bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] font-Anuphan text-sm' placeholder='Your Message' name="" id="" cols="30" rows="10"></textarea>
                <button className='px-3 py-2 font-Anuphan text-base bg-[#2A49F9] rounded-md ml-auto block'>Send</button>
            </div>
        </div>
        <Footer/>
        </motion.div>
        </>
     );
}
 
export default Contact;