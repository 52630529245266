import { createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const PageContext = createContext();
export const FeatBuyContext = createContext();
export const FeaturedName = createContext();
export const FeatureCycle = createContext();
export const FeaturePrice = createContext();
export const FeatureProfit = createContext();
export const FeatureInfo = createContext();
export const UserInfo = createContext();
export const FeatWdPopup = createContext();
export const WalletAddyData = createContext();
export const cryptoTrading = createContext();

const PageProvider = (props) => {
  const [page, setPage] = useState("Overview");
  const [featBuy, setFeatBuy] = useState(false);
  const [featName, setFeatName] = useState("daniel");
  const [featCycle, setFeatCycle] = useState("3 days");
  const [featPrice, setFeatPrice] = useState("");
  const [featProfit, setFeatProfit] = useState("");
  const [featInfo, setFeatInfo] = useState("");
  const [wdPopup, setWdPopup] = useState(false);
  const [walletAddy, setWalletAddy] = useState([]);
  const [crytoTrader, setCryptoTrader] = useState(false);
  const [userData, setUserData] = useState({
    name: "John Doe",
    mail: "Johndoe@mail.com",
    country: "USA",
    phone: "0987654",
    plan: "Starter-Plan",
    CryptoWithdrawFee: "false",
    CryptoCommisionFee: "false",
    CryptoUpgradeFee: "false",
    StocksWithdrawFee: "false",
    StocksCommisionFee: "false",
    StocksUpgradeFee: "false",
    ForexWithdrawFee: "false",
    ForexCommisionFee: "false",
    ForexUpgradeFee: "false",
    FeatWithdrawFee: "false",
    FeatCommisionFee: "false",
    FeatUpgradeFee: "false",
    CryptoTotalbal: "0",
    CryptoDeposit: "0",
    CryptoProfits: "0",
    ForexTotalbal: "0",
    ForexDeposit: "0",
    ForexProfits: "0",
    StockTotalbal: "0",
    StockDeposit: "0",
    StockProfits: "0",
    FeatTotalbal: "0",
    FeatDeposit: "0",
    FeatProfits: "0",
  });

  const firebaseConfig = {
    apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
    authDomain: "algoflare.firebaseapp.com",
    projectId: "algoflare",
    storageBucket: "algoflare.appspot.com",
    messagingSenderId: "263253915028",
    appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
    measurementId: "G-5SXDQ31257",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const colRef = collection(db, "users");
        const q = query(colRef, where("uid", "==", uid));
        onSnapshot(q, (snapshot) => {
          snapshot.docs.forEach((doc) => {
            setUserData(doc.data());
          });
        });
      } else {
      }
    });
  }, [page, auth, db]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //   const uid = user.uid;
        const docRef = doc(db, "wallets", "address");
        onSnapshot(docRef, (doc) => {
          const transData = doc.data();
          setWalletAddy(transData);
        });
      } else {
      }
    });
  }, [page, auth, db]);

  return (
    <cryptoTrading.Provider value={[crytoTrader, setCryptoTrader]}>
      <WalletAddyData.Provider value={[walletAddy, setWalletAddy]}>
        <FeatWdPopup.Provider value={[wdPopup, setWdPopup]}>
          <UserInfo.Provider value={[userData, setUserData]}>
            <FeatureInfo.Provider value={[featInfo, setFeatInfo]}>
              <FeatureProfit.Provider value={[featProfit, setFeatProfit]}>
                <FeaturePrice.Provider value={[featPrice, setFeatPrice]}>
                  <FeatureCycle.Provider value={[featCycle, setFeatCycle]}>
                    <FeaturedName.Provider value={[featName, setFeatName]}>
                      <FeatBuyContext.Provider value={[featBuy, setFeatBuy]}>
                        <PageContext.Provider value={[page, setPage]}>
                          {props.children}
                        </PageContext.Provider>
                      </FeatBuyContext.Provider>
                    </FeaturedName.Provider>
                  </FeatureCycle.Provider>
                </FeaturePrice.Provider>
              </FeatureProfit.Provider>
            </FeatureInfo.Provider>
          </UserInfo.Provider>
        </FeatWdPopup.Provider>
      </WalletAddyData.Provider>
    </cryptoTrading.Provider>
  );
};
export default PageProvider;
