import { motion } from "framer-motion";

const Banner = () => {
    return ( 
        <>
        <motion.div 
        initial={{y:200, opacity:0}}
        whileInView={{y:0, opacity:1}}
        transition={{type:'tween', duration:0.5, delay:0.3}}
        className=" px-5 pb-10 md:px-12 md:pb-16 relative">
            <div className=" w-full bg-[#003FE6] h-[250px] flex-col rounded-[20px] flex justify-center items-center space-y-3">
                <p className="text-lg md:text-3xl font-extrabold text-center font-Anuphan capitalize">Let's start your investment</p>
                <p className=" text-sm md:text-base font-normal text-center font-Anuphan capitalize">Begin your investment journey now</p>
                <button className=" text-sm md:text-base font-normal text-center font-Anuphan capitalize bg-white px-4 py-2 rounded-[8px] text-[#0046FF]">Get Started</button>
            </div>
        </motion.div>
        </>
     );
}
 
export default Banner;