// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import logo from '../images/logo.png';
import udl from '../images/udl.png';
import jdl from '../images/jdl.png';
import load from '../images/load.gif';
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, doc, addDoc } from 'firebase/firestore';
import emailjs from '@emailjs/browser';




const Signup = () => {
const Navigate = useNavigate();
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
    authDomain: "algoflare.firebaseapp.com",
    projectId: "algoflare",
    storageBucket: "algoflare.appspot.com",
    messagingSenderId: "263253915028",
    appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
    measurementId: "G-5SXDQ31257"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const db = getFirestore(app);

  const [loadValue, setLoadValue] = useState('No')
  const [name, setName] = useState('');
  const [email, setMail] = useState(null);
  const [Country, setCountry] = useState('');
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState('');
  const [plan, setPlan] = useState('');

  const serviceID = 'service_j8wphoh';
  const templateID = 'template_hgjyjth';
  const userMail = email;

  const sendEmail = () =>{
    emailjs.send(serviceID, templateID,{
        to_name: name,
        send_to: userMail,
    }, 'vUXoQ-0-B0fTk0W-_')
    .then((result) => {
        console.log(result.text);
    },
    (error) => {
        console.log(error.text);
    });
};

  const signUpBtn = () => {
    if(
      name !== '' &&
      email !== '' &&
      Country !== '' &&
      password !== '' &&
      phone !== '' &&
      plan !== ''
    ){
      setLoadValue('Yes');
      createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) =>{
        const user = userCredential.user;

        const userDocs = collection(db, 'users');

        const docData = {
          uid: user.uid,
          name: name,
          mail: email,
          country:Country,
          password:password,
          phone:phone,
          plan:plan,
          CryptoWithdrawFee : 'false',
          CryptoCommisionFee : 'false',
          CryptoUpgradeFee: 'false',
          StocksWithdrawFee : 'false',
          StocksCommisionFee : 'false',
          StocksUpgradeFee: 'false',
          ForexWithdrawFee : 'false',
          ForexCommisionFee : 'false',
          ForexUpgradeFee: 'false',
          FeatWithdrawFee : 'false',
          FeatCommisionFee : 'false',
          FeatUpgradeFee: 'false',
          CryptoTotalbal: '0',
          CryptoDeposit: '0',
          CryptoProfits: '0',
          ForexTotalbal: '0',
          ForexDeposit: '0',
          ForexProfits: '0',
          StockTotalbal: '0',
          StockDeposit: '0',
          StockProfits: '0',
          FeatTotalbal: '0',
          FeatDeposit: '0',
          FeatProfits: '0',
        }
        sendEmail()
          addDoc(userDocs, docData)
            .then(() => { 
              setLoadValue('No');
              sendEmail();
              alert('Welcome, A verification mail has been sent to your mail');
              Navigate('/dashboard');
            })
            .catch((error) =>{
              // alert('error' + error)
              const errorCode = error.code;
              const errorMessage = error.message;
              alert(errorCode)
              console.log(errorMessage)
            })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(errorCode)
          console.log(errorMessage)
         setLoadValue('No')
        });
    }
    else{
      alert('Please Complete the form')
    }
  }

  const validateName = ()=>{
    const name = document.getElementById('name');
    const namewarn = document.getElementById('nameWarn')
    if(name.value.length < 3){
      namewarn.classList.replace('hidden', 'block');
      namewarn.innerHTML='Please fill in your name'
      namewarn.style.color='red';
    }
    else if(name.value.length > 3){
      namewarn.classList.replace('block', 'hidden');
      namewarn.style.color='green';
      setName(name.value);
    }
  };

  const validateMail =()=>{
    const mail = document.getElementById('mail').value;
    const mailWarn = document.getElementById('mailWarn');

    const checkMail = (mail) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(mail).toLowerCase());
    }
    if (checkMail(mail)) {
      mailWarn.classList.replace('hidden', 'block');
      mailWarn.innerHTML='Valid Mail';
      mailWarn.style.color='green';
      setMail(mail);
    } else {
      mailWarn.classList.replace('hidden', 'block');
      mailWarn.innerHTML='Invalid Mail';
      mailWarn.style.color='red';
    }
    
  };
  const getPhone =()=>{
    const phone = document.getElementById('phone').value;
    setPhone(phone);
  }
  const getPlan =()=>{
    const plan = document.getElementById('plan').value;
    setPlan(plan);
  }

  const getCountry = ()=>{
      const country = document.getElementById('country');
      setCountry(country.value);
    };

  const validatePassword = ()=>{
    const password = document.getElementById('password');
    const passwordwarn = document.getElementById('passwordwarn')
    if(password.value.length < 6){
      passwordwarn.classList.replace('hidden', 'block');
      passwordwarn.innerHTML='Please pick a valid password'
      passwordwarn.style.color='red';
    }
    else{
      passwordwarn.classList.replace('hidden', 'block');
      passwordwarn.innerHTML='Valid password'
      passwordwarn.style.color='green';
      setPassword(password.value);
    }
  };


    return ( 
        <motion.div
        initial={{x:100, opacity:0}}
        animate={{x:0, opacity:1}}
        exit={{x:-100, opacity:0}}
        transition={{type:'spring', stiffness:80, duration:0.2}}
        >
        <img src={ udl } className=' absolute top-0 left-0 -z-[99999] w-[200px] h-[200px] md:w-[250px] md:h-[250px] blur-2xl backdrop-blur-xl' alt="" />
        <img src={ jdl } className=' absolute bottom-0 right-0 -z-[99999] w-[200px] h-[200px] md:w-[250px] md:h-[250px] blur-2xl backdrop-blur-xl' alt="" />
        <div className=" w-full flex justify-center items-center p-5 py-10 md:p-10">
          <div className="w-full flex justify-center items-center space-y-3 flex-col">
            <span className="flex flex-row items-center space-x-2">
              <img src={ logo } alt="" />
              <p className=" text-base font-normal font-Bruno">AlgoFlare</p>
            </span>
            <p className=" font-Anuphan text-xl md:text-2xl font-bold ">Get Started</p>
            <p className=" font-Anupan text-base font-normal capitalize mb-5 text-[rgba(255,255,255,0.8)]">Create an Account To get started</p>
            <div className=" space-y-4 w-full md:w-[350px] flex flex-col justify-center items-center">
              <span className="w-full flex flex-col justify-center items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                <p className=" block mr-auto">Full Name</p>
                <input type="text" onInput={ validateName } id="name" className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" placeholder="John Doe" />
                <p id='nameWarn' className=" capitalize hidden mr-auto text-xs mt-1 text-[rgba(255,255,255,0.9)]">Please fill in your name</p>
              </span>
              <span className="w-full flex flex-col justify-center items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
              <p className=" block mr-auto">Email</p>
                <input type="text" onInput={validateMail} id="mail" className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" placeholder="Johndoe@mail.com" />
                <p id='mailWarn' className=" capitalize hidden mr-auto text-xs mt-1 text-[rgba(255,255,255,0.9)]">Please fill in your Email</p>
              </span>
              <span className="w-full flex flex-col justify-center items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
              <p className=" block mr-auto">Plan</p>
                <select type="text"  id="plan" onChange={getPlan} className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" >
                  <option value="">Select a Plan</option>
                  <option value="Starter-Plan">Starter-plan</option>
                  <option value="Master-Plan">Master-plan</option>
                  <option value="Premium-Plan">Premium-plan</option>
                  <option value="Vip-Plan">Vip-plan</option>
                </select>
              </span>
              <span className="w-full flex flex-col justify-center items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
              <p className=" block mr-auto">Country</p>
                <select name="country" id="country" onChange={ getCountry } className="w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 md:pr-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                          <option value="">Select Country</option>
                                        <option>Afghanistan</option>
                                        <option>Albania</option>
                                        <option>Algeria</option>
                                        <option>American Samoa</option>
                                        <option>Andorra</option>
                                        <option>Angola</option>
                                        <option>Anguilla</option>
                                        <option>Antarctica</option>
                                        <option>Antigua &amp; Barbuda</option>
                                        <option>Argentina</option>
                                        <option>Armenia</option>
                                        <option>Aruba</option>
                                        <option>Australia</option>
                                        <option>Austria</option>
                                        <option>Azerbaijan</option>
                                        <option>Bahamas, The</option>
                                        <option>Bahrain</option>
                                        <option>Bangladesh</option>
                                        <option>Barbados</option>
                                        <option>Belarus</option>
                                        <option>Belgium</option>
                                        <option>Belize</option>
                                        <option>Benin</option>
                                        <option>Bermuda</option>
                                        <option>Bhutan</option>
                                        <option>Bolivia</option>
                                        <option>Bosnia and Herzegovina</option>
                                        <option>Botswana</option>
                                        <option>Bouvet Island</option>
                                        <option>Brazil</option>
                                        <option>British Indian Ocean Territory</option>
                                        <option>Brittany</option>
                                        <option>Brunei Darussalam</option>
                                        <option>Bulgaria</option>
                                        <option>Burkina Faso</option>
                                        <option>Burundi</option>
                                        <option>Cambodia</option>
                                        <option>Cameroon</option>
                                        <option>Canada</option>
                                        <option>Cape Verde</option>
                                        <option>Cayman Islands</option>
                                        <option>Central African Republic</option>
                                        <option>Chad</option>
                                        <option>Chile</option>
                                        <option>China</option>
                                        <option>Christmas Island</option>
                                        <option>Cocos (Keeling) Islands</option>
                                        <option>Colombia</option>
                                        <option>Comoros</option>
                                        <option>Congo</option>
                                        <option>Congo, Democratic Rep. of the</option>
                                        <option>Cook Islands</option>
                                        <option>Costa Rica</option>
                                        <option>Croatia</option>
                                        <option>Cuba</option>
                                        <option>Cyprus</option>
                                        <option>Czech Republic</option>
                                        <option>Denmark</option>
                                        <option>Djibouti</option>
                                        <option>Dominica</option>
                                        <option>Dominican Republic</option>
                                        <option>Ecuador</option>
                                        <option>Egypt</option>
                                        <option>El Salvador</option>
                                        <option>Equatorial Guinea</option>
                                        <option>Eritrea</option>
                                        <option>Estonia</option>
                                        <option>Ethiopia</option>
                                        <option>Falkland Islands (Malvinas)</option>
                                        <option>Faroe Islands</option>
                                        <option>Fiji</option>
                                        <option>Finland</option>
                                        <option>France</option>
                                        <option>French Polynesia</option>
                                        <option>French Southern Territories - TF</option>
                                        <option>Gabon</option>
                                        <option>Gambia, the</option>
                                        <option>Georgia</option>
                                        <option>Germany</option>
                                        <option>Ghana</option>
                                        <option>Gibraltar</option>
                                        <option>Greece</option>
                                        <option>Greenland</option>
                                        <option>Grenada</option>
                                        <option>Guam</option>
                                        <option>Guatemala</option>
                                        <option>Guiana, French</option>
                                        <option>Guinea</option>
                                        <option>Guinea-Bissau</option>
                                        <option>Guinea, Equatorial</option>
                                        <option>Guyana</option>
                                        <option>Haiti</option>
                                        <option>Heard and McDonald Islands</option>
                                        <option>Honduras</option>
                                        <option>Hong Kong, (China)</option>
                                        <option>Hungary</option>
                                        <option>Iceland</option>
                                        <option>India</option>
                                        <option>Indonesia</option>
                                        <option>Iran, Islamic Republic of</option>
                                        <option>Iraq</option>
                                        <option>Ireland</option>
                                        <option>Israel</option>
                                        <option>Italy</option>
                                        <option>Ivory Coast (see Cote d'Ivoire)</option>
                                        <option>Jamaica</option>
                                        <option>Japan</option>
                                        <option>Jordan</option>
                                        <option>Kazakhstan</option>
                                        <option>Kenya</option>
                                        <option>Kiribati</option>
                                        <option>Korea, (South) Republic of</option>
                                        <option>Korea, Demo. People's Rep. of</option>
                                        <option>Kuwait</option>
                                        <option>Kyrgyzstan</option>
                                        <option>Lao People's Democratic Republic</option>
                                        <option>Latvia</option>
                                        <option>Lebanon</option>
                                        <option>Lesotho</option>
                                        <option>Liberia</option>
                                        <option>Libyan Arab Jamahiriya</option>
                                        <option>Liechtenstein</option>
                                        <option>Lithuania</option>
                                        <option>Luxembourg</option>
                                        <option>Macao, (China)</option>
                                        <option>Macedonia, TFYR</option>
                                        <option>Madagascar</option>
                                        <option>Malawi</option>
                                        <option>Malaysia</option>
                                        <option>Maldives</option>
                                        <option>Mali</option>
                                        <option>Malta</option>
                                        <option>Marshall Islands</option>
                                        <option>Martinique</option>
                                        <option>Mauritania</option>
                                        <option>Mauritius</option>
                                        <option>Mayotte</option>
                                        <option>Mexico</option>
                                        <option>Micronesia, Federated States of</option>
                                        <option>Moldova, Republic of</option>
                                        <option>Monaco</option>
                                        <option>Mongolia</option>
                                        <option>Montserrat</option>
                                        <option>Morocco</option>
                                        <option>Mozambique</option>
                                        <option>Myanmar (ex-Burma)</option>
                                        <option>Namibia</option>
                                        <option>Nauru</option>
                                        <option>Nepal</option>
                                        <option>Netherlands</option>
                                        <option>Netherlands Antilles</option>
                                        <option>New Caledonia</option>
                                        <option>New Zealand</option>
                                        <option>Nicaragua</option>
                                        <option>Niger</option>
                                        <option>Nigeria</option>
                                        <option>Niue</option>
                                        <option>Norfolk Island</option>
                                        <option>Northern Mariana Islands</option>
                                        <option>Norway</option>
                                        <option>Oman</option>
                                        <option>Pakistan</option>
                                        <option>Palau</option>
                                        <option>Panama</option>
                                        <option>Papua New Guinea</option>
                                        <option>Paraguay</option>
                                        <option>Peru</option>
                                        <option>Philippines</option>
                                        <option>Pitcairn Island</option>
                                        <option>Poland</option>
                                        <option>Portugal</option>
                                        <option>Puerto Rico</option>
                                        <option>Qatar</option>
                                        <option>Reunion</option>
                                        <option>Romania</option>
                                        <option>Russia (Russian Federation)</option>
                                        <option>Rwanda</option>
                                        <option>S. Georgia and S. Sandwich Is.</option>
                                        <option>Saint Helena</option>
                                        <option>Saint Kitts and Nevis</option>
                                        <option>Saint Lucia</option>
                                        <option>Saint Pierre and Miquelon</option>
                                        <option>Saint Pierre and Miquelon</option>
                                        <option>Saint Pierre and Miquelon</option>
                                        <option>Saint Vincent and the Grenadines</option>
                                        <option>Samoa</option>
                                        <option>San Marino</option>
                                        <option>Sao Tome and Principe</option>
                                        <option>Saudi Arabia</option>
                                        <option>Senegal</option>
                                        <option>Seychelles</option>
                                        <option>Sierra Leone</option>
                                        <option>Singapore</option>
                                        <option>Slovakia</option>
                                        <option>Slovenia</option>
                                        <option>Solomon Islands</option>
                                        <option>Somalia</option>
                                        <option>South Africa</option>
                                        <option>Spain</option>
                                        <option>Sri Lanka (ex-Ceilan)</option>
                                        <option>Sudan</option>
                                        <option>Suriname</option>
                                        <option>Svalbard and Jan Mayen Islands</option>
                                        <option>Swaziland</option>
                                        <option>Sweden</option>
                                        <option>Switzerland</option>
                                        <option>Syrian Arab Republic</option>
                                        <option>Taiwan</option>
                                        <option>Tajikistan</option>
                                        <option>Tanzania, United Republic of</option>
                                        <option>Thailand</option>
                                        <option>Timor-Leste (East Timor)</option>
                                        <option>Togo</option>
                                        <option>Tokelau</option>
                                        <option>Tonga</option>
                                        <option>Trinidad &amp; Tobago</option>
                                        <option>Tunisia</option>
                                        <option>Turkey</option>
                                        <option>Turkmenistan</option>
                                        <option>Turks and Caicos Islands</option>
                                        <option>Tuvalu</option>
                                        <option>Uganda</option>
                                        <option>Ukraine</option>
                                        <option>United Arab Emirates</option>
                                        <option>United States</option>
                                        <option>United Kingdom</option>
                                        <option>Uruguay</option>
                                        <option>US Minor Outlying Islands</option>
                                        <option>Uzbekistan</option>
                                        <option>Vanuatu</option>
                                        <option>Vatican City State (Holy See)</option>
                                        <option>Venezuela</option>
                                        <option>Viet Nam</option>
                                        <option>Virgin Islands, British</option>
                                        <option>Virgin Islands, U.S.</option>
                                        <option>Wallis and Futuna</option>
                                        <option>Western Sahara</option>
                                        <option>Yemen</option>
                                        <option>Zambia</option>
                                        <option>Zimbabwe</option>
                                    </select>
              </span>
              <span className="w-full flex flex-col justify-center items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
              <p className=" block mr-auto">Phone</p>
                <input type="text" id="phone" onInput={ getPhone } className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" placeholder="Enter Phone" />
              </span>
              <span className="w-full flex flex-col justify-center items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)] relative">
              <p className=" block mr-auto">Password</p>
                <input type="password" id="password" onInput={ validatePassword } className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" placeholder="Password must be 6+ characters" />
                <p id='passwordwarn' className=" capitalize hidden mr-auto text-xs mt-1 text-[rgba(255,255,255,0.9)]">Please fill in your Email</p>
              </span>
              <span className="w-full flex flex-col justify-center items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                <p className=" block mr-auto">Repeat Password</p>
                <input type="password" id="password2" className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" placeholder="Confirm your password" />
              </span>
              <button id="signUp" onClick={ signUpBtn } className=" w-full md:w-[350px] h-[44px] flex justify-center items-center bg-[#2A49F9] text-base font-normal font-Anuphan rounded-md ">
                { loadValue === 'No' && 'Create Account' }
                { loadValue === 'Yes' && <img src={ load } className=' w-6 h-6' alt="" />}
              </button>
              <p className=" text-[rgba(255,255,255,0.8)] font-Anuphan text-sm">Already Have an Account?<Link to='/Signin'><span className=" text-[#0046FF]"> Sign in</span></Link></p>
            </div>
          </div>
        </div>
        </motion.div>
     );
}
 
export default Signup;