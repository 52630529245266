import  { useEffect } from 'react';


const AnalysisWidget = () => {

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      interval: '1m',
      width: '100%',
      isTransparent: true,
      height: '100%',
      symbol: 'BINANCE:BTCUSDT',
      showIntervalTabs: true,
      locale: 'en',
      colorTheme: 'dark'
    });

    const container = document.getElementById('tradingview-technical-analysis-widget');
    container.appendChild(script);

    return () => {
      container.removeChild(script);
    };
  }, []);

  return (
    <div id="tradingview-technical-analysis-widget" className="tradingview-widget-container overflow-hidden">
      <div className="tradingview-widget-container__widget overflow-hidden"></div>
    </div>
  );
};

export default AnalysisWidget;
