import Banner from "../comps/banner";
import Charts from "../comps/charts";
import Footer from "../comps/footer";
import Hero from "../comps/hero";
import Navbar from "../comps/navbar";
import Offer from "../comps/offer";
import Started from "../comps/started";
import Testimonial from "../comps/testimonial";
import Why from "../comps/why";
import { motion } from "framer-motion";

const Home = () => {
    return ( 
        <>
        <Navbar/>
        <motion.div
        initial={{x:100, opacity:0}}
        animate={{x:0, opacity:1}}
        exit={{x:-100, opacity:0}}
        transition={{type:'spring', stiffness:80, duration:0.2}}
        >
        <Hero/>
        <Charts/>
        <Started/>
        <Offer/>
        <Why/>
        <Testimonial/>
        <Banner/>
        <Footer/>
        </motion.div>
        </>
     );
}
 
export default Home;