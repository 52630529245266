import { motion } from "framer-motion";
import Headerdash from "../comps/headerDash";
import Sidebardash from "../comps/sidebarDash";
import jdl from '../images/jdl.png';
import OverviewDash from "../comps/overviewDash";
import CryptoDash from "../comps/cryptoDash";
import { useContext, useEffect } from 'react';
import { PageContext } from '../contexts/pageContext';
import ForexDash from "../comps/forexDash";
import StockDash from "../comps/stockDash";
import menu from '../images/menu.png';
import Upgrade from "../comps/upgrade";
import Profile from "../comps/profile";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import Transactions from "../comps/transaction";

const Dashboard = () => {
    const Navigate = useNavigate();

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      const auth = getAuth();

    const [page, setPage] = useContext(PageContext); 

    useEffect(()=>{

        const overview = document.getElementById('overview');
        const crypt = document.getElementById('crypto');
        const forex = document.getElementById('forex');
        const stock = document.getElementById('stock');
        const upgrade = document.getElementById('upgrade');
        const profile = document.getElementById('profile');
        const transaction = document.getElementById('transaction');

        if(page === 'Overview'){
            overview.style.display = 'block';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            upgrade.style.display = 'none';
            profile.style.display = 'none';
            transaction.style.display = 'none';
        }
        else if(page === 'Crypto'){
            overview.style.display = 'none';
            crypt.style.display = 'block';
            forex.style.display = 'none';
            stock.style.display = 'none';
            upgrade.style.display = 'none';
            profile.style.display = 'none';
            transaction.style.display = 'none';
        }
        else if(page === 'Forex'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'block';
            stock.style.display = 'none';
            upgrade.style.display = 'none';
            profile.style.display = 'none';
            transaction.style.display = 'none';
        }
        else if(page === 'Stocks'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'block';
            upgrade.style.display = 'none';
            profile.style.display = 'none';
            transaction.style.display = 'none';
        }
        else if(page === 'Upgrade'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            upgrade.style.display = 'block';
            profile.style.display = 'none';
            transaction.style.display = 'none';
        }
        else if(page === 'Settings'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            upgrade.style.display = 'none';
            profile.style.display = 'block';
            transaction.style.display = 'none';
        }
        else if(page === 'Transactions'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            upgrade.style.display = 'none';
            profile.style.display = 'none';
            transaction.style.display = 'block';
        };
    });
    useEffect(()=>{
        const menuBtn = document.getElementById('menu');
        const overlay = document.getElementById('overlay');

        menuBtn.addEventListener('click', ()=>{
        const isClose = overlay.classList.contains('-translate-x-[100%]');
            if (isClose) {
                overlay.classList.remove('-translate-x-[100%]');
            } else {
                overlay.classList.add('-translate-x-[100%]');
            }
        });
    },[])

    useEffect(()=>{
        const overlay = document.getElementById('overlay');
        overlay.classList.add('-translate-x-[100%]');
    },[page])
    
    useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
              const uid = user.uid;
            }else{
                Navigate('/Signin')
            }
        });
    })
    
    return ( 
        <motion.div
        initial={{x:100, opacity:0}}
        animate={{x:0, opacity:1}}
        exit={{x:-100, opacity:0}}
        transition={{type:'spring', stiffness:80, duration:0.2}}
         className=" w-full">
            <img src={ menu } id="menu" className=" block md:hidden absolute z-[9999] top-5 left-3 w-6 h-6" alt="" />
            <img src={ jdl } className=' fixed bottom-0 right-0 -z-[99999] w-[200px] h-[200px] md:w-[250px] md:h-[250px] blur-2xl backdrop-blur-xl' alt="" />
                <Headerdash/>
                <div id="overlay" className='-translate-x-[100%]  md:-translate-x-0 transition-all duration-500 w-[80%] md:w-[80px] lg:w-1/5 border-r shadow-md md:shadow-[0px] h-[100vh] py-3 px-3 md:px-5 fixed top-0 left-0 bg-[rgba(0,0,0,1)] backdrop-blur-[15px] md:backdrop-blur-none md:bg-transparent border-[rgba(255,255,255,0.2)] z-[999]'>
                    <Sidebardash/>
                </div>
                <div id="overview" className="">
                    <OverviewDash/>
                </div>
                <div id="crypto" className="">
                    <CryptoDash/>
                </div>
                <div id="forex" className="">
                    <ForexDash/>
                </div>
                <div id="stock" className="">
                    <StockDash/>
                </div>
                <div id="upgrade" className="">
                    <Upgrade/>
                </div>
                <div id="profile" className="">
                    <Profile/>
                </div>
                <div id="transaction" className="">
                    <Transactions/>
                </div>
        </motion.div>
     );
}
 
export default Dashboard;