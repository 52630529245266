import React, { useRef, useEffect } from 'react';

function ETHUSDTWidget() {
  const tradingviewRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      "symbol": "BINANCE:ETHUSDT",
      "width": "100%",
      "colorTheme": "dark",
      "isTransparent": true,
      "locale": "en"
    });

    tradingviewRef.current.appendChild(script);
    return () => {
      tradingviewRef.current.removeChild(script);
    }
  }, []);

  return (
    <div className="tradingview-widget-container" ref={tradingviewRef}>
      <div className="tradingview-widget-container__widget"></div>

    </div>
  );
}

export default ETHUSDTWidget;
