import AnalysisWidget from "./cryptoTimeline";
import crypto from '../images/cryptocurrency.png';
import withdraw from '../images/withdraw.png';
import OverviewCrypto from "./overviewCrypto";
import ETHUSDTWidget from "./smallWidget";
import plus from '../images/cancel.png';
import failed from '../images/fail.png';
import { useContext, useState, useRef, useEffect } from "react";
import { UserInfo, WalletAddyData, cryptoTrading, PageContext } from "../contexts/pageContext";
import load from '../images/load.gif';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, query, where, onSnapshot, updateDoc, doc} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import copy from '../images/copy.png';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import emailjs from '@emailjs/browser';
import btcImg from '../images/btc.png';
import ethImg from '../images/ethqr.png';
import dogeImg from '../images/DogeQR.png';
import usdtImg from '../images/usdtQR.png';
import axios from 'axios';
import arrDown from '../images/arrDown.svg';
import { motion } from "framer-motion";
import close from '../images/close.svg';
import arrLeft from '../images/arrLeft.svg'
import arrRight from '../images/arrRight.svg';
import danger from '../images/danger.svg';
import tickCircle from '../images/tick-circle.svg';

const CryptoDash = () => {
    const [userData, setUserData] = useContext(UserInfo);
    const [page, setPage] = useContext(PageContext);
    const [walletAddy, setWalletAddy] = useContext(WalletAddyData);
    const [crytoTrader, setCryptoTrader] = useContext(cryptoTrading);
    const [depoPopup, setDepoPopup ] = useState(false);
    const [ineliPopup, setIneliPopup] = useState(false);
    const [withdrawPopup, setWithdrawPopup] = useState(false);
    const [loading, setLoading] = useState('No');
    const [wdLoading, setWdLoading] = useState('No');

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const auth = getAuth();
      const storage = getStorage(app);

    //date and time
    // For todays date;
    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }
    // For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    const handleAmount = (e)=>{
        const newAmount = e.target.value;
        setAmount(newAmount);
    } 

    function handleChange(event) {
        setFile(event.target.files[0]);
    };

    const newDate = new Date();
    const date =  newDate.today();
    const time = newDate.timeNow();

    //document data
    const userName = userData.name;
    const userUid = userData.uid;
    const transType = 'deposit';
    const transState = 'Pending';
    const [file, setFile] = useState(null);
    const [amount, setAmount] = useState('0');
    const imageName = userName + date;

    // handle email sends
    const serviceID = 'service_pxrmptj';
    const templateID = 'template_h4zpzix';
    const userMail = userData.mail;

    const sendEmail = () =>{
        emailjs.send(serviceID, templateID,{
            to_name: userName,
            Amount: '$' + (amount),
            send_to: userMail,
        }, 'vUXoQ-0-B0fTk0W-_')
        .then((result) => {
            console.log(result.text);
        },
        (error) => {
            console.log(error.text);
        });
    };

    const sumbmitImg = ()=>{
        setLoading('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName,
        };
        const storageRef = ref(storage, imageName, metadata);

        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                const transactionDoc = collection(db, 'transactions');
                const docData = {
                    name: userName,
                    uid:userUid,
                    transactionType:transType,
                    transactionState:transState,
                    tag:'Crypto',
                    amount:amount,
                    date:date,
                    url:url,
                    time:time,
                }
                addDoc(transactionDoc, docData)
                .then(()=>{
                    setLoading('No');
                    sendEmail();
                    const confirm = document.getElementById('confirm');
                    confirm.style.display = 'block';
                });
            });
        });
    };
    const [wdAmount, setWdAmount] = useState('');
    const submitWithdraw = ()=>{
        const transactionDoc = collection(db, 'transactions');
        const docData = {
            name: userName,
            uid:userUid,
            transactionType:'withdrawal',
            transactionState:transState,
            tag:'Crypto',
            amount: wdAmount,
            date:date,
            time:time,
        }
        addDoc(transactionDoc, docData)
        .then(()=>{

        })
    };

    function withdrawalMethod(){
        const btcBlock = document.getElementById('btcMeth');
        const ppBlock = document.getElementById('ppMeth');
        const wuBlock = document.getElementById('wuMeth');
        const payMethod = document.getElementById('payMethod').value;
        if(payMethod === 'btc'){
            btcBlock.style.display = 'block';
            ppBlock.style.display = 'none';
            wuBlock.style.display = 'none';
        }
        else if(payMethod === 'pal'){
            btcBlock.style.display = 'none';
            ppBlock.style.display = 'block';
            wuBlock.style.display = 'none';
        }
        else if(payMethod === 'wu'){
            btcBlock.style.display = 'none';
            ppBlock.style.display = 'none';
            wuBlock.style.display = 'block';
        }
    };

    //check deposit balance
    const btcBalCheck = ()=>{
        const btcWarn = document.getElementById('btcWarn');
        setWdLoading('Yes')
        if(userData.CryptoDeposit === '0'){
            setWdLoading('No');
            btcWarn.style.display = 'block';
        }
        else if(userData.CryptoDeposit !== '0' && userData.CryptoUpgradeFee == 'false'){
            setIneliPopup(true);
            setStatus('upgrade');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.CryptoDeposit !== '0' && userData.CryptoUpgradeFee !== 'false' && userData.CryptoWithdrawFee == 'false'){
            setIneliPopup(true);
            setStatus('withdraw');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.CryptoDeposit !== '0' && userData.CryptoUpgradeFee !== 'false' && userData.CryptoWithdrawFee !== 'false' && userData.CryptoCommisionFee == 'false'){
            setIneliPopup(true);
            setStatus('commission');
            setWdLoading('No');
            submitWithdraw();
        }
        else if(userData.CryptoDeposit !== '0' && userData.CryptoUpgradeFee !== 'false' && userData.CryptoWithdrawFee !== 'false' && userData.CryptoCommisionFee !== 'false'){
            setIneliPopup(false);
            setWdLoading('No');
            submitWithdraw();
            alert('Recieved, We are processing your Request')
        }
    };      

    const handleCopyClick = (e) =>{
        const parent = e.target.parentElement;
        const elements = parent.children;
        const addy = elements[0];
        addy.select();
        addy.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(addy.value);
    }
   
    const [status, setStatus] = useState('');


    const [qrPopup, setQrPopup] = useState(false);
    const [ Qrbtc, setQrbtc] = useState(false);
    const [ Qreth, setQreth] = useState(false);
    const [ Qrdoge, setQrdoge] = useState(false);
    const [ Qrusdt, setQrusdt] = useState(false);

    const getBtcQr = ()=>{
        setQrPopup(true);
        setQrbtc(true);
        setQreth(false);
        setQrdoge(false);
        setQrusdt(false);
    };
    const getEthQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(true);
        setQrdoge(false);
        setQrusdt(false);
    };
    const getDogeQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(false);
        setQrdoge(true);
        setQrusdt(false);
    };
    const getUsdtQr = ()=>{
        setQrPopup(true);
        setQrbtc(false);
        setQreth(false);
        setQrdoge(false);
        setQrusdt(true);
    };

    // all the func for mockbinance are here 
    const [dataSet, setDataSet] = useState([]);
  const [tradeName, setTradeName] = useState('BTC');
  const [tradePopup, setTradePopup] = useState(false);
  const [dangerWarn, setDangerWarn] = useState(false);
  const [mockAmount, setmockAmount] = useState(26140.58);
  const [availBal, setAvailBal] = useState(0);
  const [liquid, setLiquid] = useState(0);
  const [tradeState, setTradeState] = useState('loss');
  const [percentage, setPercentage] = useState(20);
  const [profitNloss, setprofitNloss] = useState(0);
  const isUpdating = useRef(false);
  const timeoutId = useRef(null);
  const percentageIncrease = ([0.014, 0.012, 0.018, 0.02, 0.017, 0.015]);
  const [pnlComp, setPnlComp] = useState(false);
  const [profitEarned, setProfitEarned] = useState(false);
  const [LossEarned, setLossEarned] = useState(false);

  useEffect(()=>{
    setAvailBal(parseInt(userData.CryptoDeposit));
  },[userData]);

  // Function to get a random figure from the array
    function getRandomFigure(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
    }

  useEffect(() => {
    // Define the API endpoint
    const apiUrl = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en';

    // Use Axios to fetch data from the API
    axios
      .get(apiUrl)
      .then((response) => {
        const allExchanges = response.data;
        setDataSet(allExchanges.slice(0, 15));
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
}, []);

// Function to determine CSS class based on price change
const getPriceChangeClass = (change) => {
    return change >= 0 ? 'positive' : 'negative';
};


const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [buySellButton, setBuySellButton] = useState('buy');
  const [unit, setUnit] = useState(25); 
  const [tradeId, setTradeId] = useState('');
  const quantity = 0.0638;
  const [price1, setPrice1] = useState([]) ;
  const [price2, setPrice2] = useState([]) ;
  const [quantity1, setQuantity1] = useState([]) ;
  const [quantity2, setQuantity2] = useState([]) ;
  const [percentagePrice, setPercentagePrice] = useState((unit/100) * availBal)

  const buttons = [
    { text: '25', bgColor: '#ffffffcc', textColor: '#ffffffcc' },
    { text: '50', bgColor: '#ffffffcc', textColor: '#ffffffcc' },
    { text: '75', bgColor: '#ffffffcc', textColor: '#ffffffcc' },
    { text: '100', bgColor: '#ffffffcc', textColor: '#ffffffcc' },
  ];

  const handleButtonClick = (index, value) => {
    setActiveButtonIndex(index);
    setUnit(value);
  };

  useEffect(()=>{
    setPercentagePrice((unit/100) * availBal)
  },[unit])

  const handleBuySellButtonClick = (buttonType) => {
    setBuySellButton(buttonType);
  };

  useEffect(() => {
    // Define the range for random number
    const range = 100;
    

    // Function to generate random numbers with 2 decimal places within the specified range
    function generateRandomNumbers(mockAmount, range) {
      const min = mockAmount - range;
      const max = mockAmount + range;
      const randomNumbers = [];

      for (let i = 0; i < 6; i++) {
        const randomNumber = (Math.random() * (max - min) + min).toFixed(2);
        randomNumbers.push(Number(randomNumber));
      }
      return randomNumbers;
    }

    // Function to generate random non-negative numbers with 4 or 5 decimal places within the specified range
    function generateRandomNonNegativeDecimal(quantity, range) {
      const range1 = 1;
      const min = Math.max(0, quantity - range1);
      const max = quantity + range1;
      const randomNumbers = [];

      for (let i = 0; i < 6; i++) {
        const decimalPlaces = Math.random() > 0.5 ? 4 : 5; // Randomly choose 4 or 5 decimal places
        const randomNumber = (Math.random() * (max - min) + min).toFixed(decimalPlaces);
        randomNumbers.push(Number(randomNumber));
      }
      return randomNumbers;
    }

    // Function to update and display both random numbers
    function updateRandomNumbers() {
      const randomPrice = generateRandomNumbers(mockAmount, range);
      const randomPrice1 = generateRandomNumbers(mockAmount, range);
      const randomQuantity = generateRandomNonNegativeDecimal(quantity, range);
      const randomQuantity1 = generateRandomNonNegativeDecimal(quantity, range);
      setPrice1(randomPrice);
      setPrice2(randomPrice1);
      setQuantity1(randomQuantity);
      setQuantity2(randomQuantity1);
    }

    // Initial call to display random numbers
    updateRandomNumbers();

    // Update random numbers every 8 seconds
    const interval = setInterval(updateRandomNumbers, 3000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [mockAmount, quantity]);

const getTradeinfo = (e)=>{
  const btnParent = e.target.parentElement;
  const parentParent = btnParent.parentElement;
  const elements = parentParent.children;
  const nameInfo = elements[0].children;
  const priceInfo = elements[1].innerHTML;
  const nameInfoElements = nameInfo[0].children;
  const name = nameInfoElements[1].innerHTML;
  const price = parseFloat(priceInfo.replace("$", ""));
  setmockAmount(price)
  setTradeName(name);
  setTradePopup(true);
}

//dropdown func

const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Limit'); // Set a default value

  const options = ['Limit', 'Market'];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setLimit(mockAmount);
  }, [mockAmount]);

  const [limit, setLimit] =useState(0)
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close the dropdown when an option is selected
  };

  //increace and reduce limit

  const handleIncrease = () => {
    setLimit(limit + 1);
  };

  const handleDecrease = () => {
    if (mockAmount > 0) {
      setLimit(limit - 1);
    }
  };
  const handleInputChange = (event) => {
    const newLimit = parseFloat(event.target.value);
    if (!isNaN(newLimit)) {
      setLimit(newLimit);
    }
  };

  useEffect(() => {
    const calculateLiquid = () => {
      // Choose a random percentage reduction (5%, 7%, or 9%)
      const percentages = [0.000115, 0.0035, 0.0002];
      const randomPercentage = percentages[Math.floor(Math.random() * percentages.length)];

      // Calculate the 'liquid' by reducing 'mockAmount' by the chosen percentage
      const newLiquid = (mockAmount - mockAmount * randomPercentage)
      setLiquid(newLiquid);
    };

    calculateLiquid();
  }, [mockAmount]);

  const formattedLiquid = liquid > 100 ? liquid.toFixed(2) : liquid.toFixed(6);

  const updatePercentage = () => {
    if (!isUpdating.current) {
      isUpdating.current = true;

      const randomInterval = Math.random() * 2000 + 1000; // Random interval between 1 and 3 seconds
      const shouldIncrease = Math.random() < 0.5; // 50% chance to increase
      const changeAmount = Math.random() * 5; // Adjust the change amount as needed

      timeoutId.current = setTimeout(() => {
        if (!isUpdating.current) {
          return; // Check if updates should continue
        }

        if (tradeState === 'gain') {
          if (shouldIncrease) {
            setPercentage((prevPercentage) => prevPercentage + changeAmount);
          } else {
            setPercentage((prevPercentage) => prevPercentage - changeAmount);
          }
        } else if (tradeState === 'loss') {
          if (shouldIncrease) {
            setPercentage((prevPercentage) => prevPercentage + changeAmount);
          } else {
            setPercentage((prevPercentage) => prevPercentage - changeAmount);
          }

          if (!isUpdating.current) {
            clearTimeout(timeoutId.current); // Stop further updates
          }
        }

        isUpdating.current = false;
        updatePercentage(); // Schedule the next update
      }, randomInterval);
    }
  };


  const startOrder = ()=>{
    if(percentagePrice > availBal || percentagePrice === 0){
        setDangerWarn(true);
    }
    else{
        setPnlComp(true);
        updatePercentage();
        const transactionDoc = collection(db, 'trades');
        const docData = {
            name: userName,
            uid:userUid,
            tradeState:tradeState,
            amount: percentagePrice,
            date:date,
            time:time,
            profit: '',
            loss:'',
        }
        addDoc(transactionDoc, docData)
        .then((docRef)=>{
            const newDocId = docRef.id;
            setTradeId(newDocId);
        })
    }
  };

  useEffect(()=>{
    onAuthStateChanged(auth, (user) =>{
        if (user){
          const uid = user.uid;
          const colRef = collection(db, 'trades');
          const q = query(colRef, where('uid', '==', uid))
          onSnapshot(q,(snapshot)=>{
            snapshot.docs.forEach((doc)=>{
                setTradeState(doc.data().tradeState)
            })
          })
        }else{
        }
    });
},[page, auth, db]);

// console.log(tradeState)

  useEffect(()=>{
    const PNL = ((percentage/100)*percentagePrice);
    setprofitNloss(PNL);
  },[percentage, percentagePrice]);

  const stopUpdating = () => {
    isUpdating.current = false; // Set the flag to stop further updates
    clearTimeout(timeoutId.current); // Clear the timeout

    const tradeDoc = doc(db, 'trades', tradeId);
    const updatedProfitData = {
        profit: parseInt(profitNloss),
    };
    const updatedLossData = {
        loss: parseInt(profitNloss),
    };
    if(tradeState === 'gain'){
        updateDoc(tradeDoc, updatedProfitData)
        .then(() => {
            setProfitEarned(true);
            setPnlComp(false);
        })
        .catch((error) => {
            console.error('Error updating document: ', error);
        });
    }
    else if(tradeState === 'loss'){
        updateDoc(tradeDoc, updatedLossData)
        .then(() => {
            setLossEarned(true);
            setPnlComp(false);
            // alert('loss')
        })
        .catch((error) => {
            console.error('Error updating document: ', error);
        });
    }
    
    
  };




    return ( 
        <div>
            { dangerWarn && <div className="' w-[100%] h-[100%] fixed z-[9999] px-5 top-0 md:pt-10 pb-5 overflow-auto left-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
                <div className="bg-[#1e1e1e] p-3 w-full md:w-[280px] relative flex-col rounded-xl flex justify-center items-center">
                <img src={ close } className=' absolute top-5 right-4' onClick={()=>{setDangerWarn(false)}} alt="" />
                    <img src={ danger } alt="" />
                    <p className=" text-lg font-Anuphan text-white text-center font-medium">Insufficient Balance or Please input a volume amount</p>
                </div>
            </div>}
            { profitEarned && <div className="' w-[100%] h-[100%] fixed z-[9999] px-5 top-0 md:pt-10 pb-5 overflow-auto left-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
                <div className="bg-[#1e1e1e] p-3 w-full md:w-[280px] relative flex-col rounded-xl flex justify-center items-center">
                <img src={ close } className=' absolute top-5 right-4' onClick={()=>{setProfitEarned(false)}} alt="" />
                    <img src={ tickCircle } alt="" />
                    <p className=" text-lg font-Anuphan text-white text-center font-medium">Your Profits ${parseInt(profitNloss)} will be added to your account shortly</p>
                </div>
            </div>}
            { LossEarned && <div className="' w-[100%] h-[100%] fixed z-[9999] px-5 top-0 md:pt-10 pb-5 overflow-auto left-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
                <div className="bg-[#1e1e1e] p-3 w-full md:w-[280px] relative flex-col rounded-xl flex justify-center items-center">
                <img src={ close } className=' absolute top-5 right-4' onClick={()=>{setLossEarned(false)}} alt="" />
                    <img src={ danger } alt="" />
                    <p className=" text-lg font-Anuphan text-white text-center font-medium">Your loss ${parseInt(profitNloss)} will be deducted from your account shortly</p>
                </div>
            </div>}
            { tradePopup &&
            <motion.div className=' w-[100%] h-[100%] fixed z-[999] px-5 top-0 md:pt-10 pb-5 overflow-auto left-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center'> 
            <div className=" flex justify-center flex-col items-center md:mt-10 w-full md:w-[420px] relative text-white md:mx-auto bg-[#121212]">
            <img src={ close } className=' absolute top-5 right-4' onClick={()=>{setTradePopup(false);setPnlComp(false)}} alt="" />
            <div className=" px-2 pt-3 md:px-6 md:pt-6 flex w-full h-full">
                <div className=' w-full'>
                <div className=" py-3 flex w-full justify-between items-center border-b border-[#cbcbcb6c]">
                    <span className="">
                    <p className=" font-Anuphan font-semibold text-xl flex flex-row uppercase">{tradeName}/USD<span className=" text-[#30B278] text-base font-normal flex flex-row items-center ml-3">  {getRandomFigure(percentageIncrease)}% <img src={ arrDown } className=' h-4 w-4 ' alt="" /></span></p>
                    </span>
                </div>
                <div className=' py-3 flex flex-row justify-between border-b border-[#cbcbcb6c]'>
                    <div className=' w-[59%]'>
                    <div className='w-full h-[35px] bg-[#1e1e1e] rounded-3xl'>
                        <button
                        className={`w-1/2 h-full ${
                            buySellButton === 'buy' ? 'bg-[#2A49F9] rounded-3xl' : ''
                        } text-base font-Anuphan font-normal`}
                        onClick={() => handleBuySellButtonClick('buy')}
                        >
                        Buy
                        </button>
                        <button
                        className={`w-1/2 h-full ${
                            buySellButton === 'sell' ? 'bg-[#2A49F9] rounded-3xl' : ''
                        } text-base font-Anuphan font-normal`}
                        onClick={() => handleBuySellButtonClick('sell')}
                        >
                        Sell
                        </button>
                    </div>
                    <div className=' w-full flex justify-between py-3 px-1'>
                        <p className=' font-Anuphan text-sm font-medium text-[#ffffffcc]'>Available</p>
                        <p className=' font-Anuphan text-sm font-medium text-[#ffffffcc]'>{'$'+availBal}</p>
                    </div>
                    <div className=' px-1 w-full space-y-3'>
                    <div className='w-full flex h-full relative'>
                        <div className='relative w-full'>
                            <div
                              className='w-full p-2 text-sm font-Anuphan font-medium text-center bg-[#1e1e1e] rounded-lg'
                              onClick={toggleDropdown}
                            >
                              {selectedOption}
                            </div>
                            {isOpen && (
                              <div className='absolute z-10 top-10 right-0 bg-[#1e1e1e] w-full rounded-md shadow-md'>
                                <ul>
                                  {options.map((option) => (
                                    <li
                                      key={option}
                                      onClick={() => handleOptionClick(option)}
                                      className='p-2 text-sm font-Anuphan font-medium cursor-pointer'
                                    >
                                      {option}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          <img
                            src={arrRight}
                            className={`absolute top-2 right-2 transform ${isOpen ? 'rotate-90' : 'rotate-0'}`}
                            alt=''
                            onClick={toggleDropdown}
                          />
                        </div>
                        <label className=' capitalize text-xs font-Anuphan text-[#ffffff6e]'>
                          Amount<span className=' uppercase'>({tradeName})</span>
                          <input type="text" placeholder="Amount" className=' w-full p-2 text-sm font-Anuphan font-medium text-center text-[#ffffffcc] bg-[#1e1e1e] rounded-lg' readOnly value={(percentagePrice / mockAmount).toFixed(6)}/>
                        </label>
                        <div className='w-full h-full flex justify-center items-center relative'>
                          <label className=' capitalize text-xs font-Anuphan text-[#ffffff6e]'>
                          Vol(USDT)
                          <input type="text" placeholder="0" onChange={(e)=>{setPercentagePrice(e.target.value)}} className=' w-full p-2 text-sm font-Anuphan font-medium text-center text-[#ffffffcc] bg-[#1e1e1e] rounded-lg' value={percentagePrice}/>
                          </label>
                        </div>
                    </div>
                    <div className='flex w-full justify-between pt-2 px-1'>
                        {buttons.map((button, index) => (
                        <label
                            key={index}
                            className={`text-center flex flex-col font-Anuphan text-xs ${
                            activeButtonIndex === index ? 'text-[#2A49F9]' : 'text-[#ffffffcc]'
                            }`}
                        >
                            <button
                            className={`w-[40px] h-1 ${
                                activeButtonIndex === index ? 'bg-[#2A49F9]' : 'bg-[#ffffffcc]'
                            }`}
                            onClick={() => handleButtonClick(index, button.text)}
                            ></button>
                            {button.text+'%'}
                        </label>
                        ))}
                    </div>

                    <label className=' capitalize text-xs font-Anuphan text-[#ffffff6e]'>
                        Limit(USDT)
                        <div className=' w-full justify-center items-center relative'>
                          <input type="text" placeholder="Amount" className=' w-full p-2 text-sm font-Anuphan font-medium text-center text-[#ffffffcc] bg-[#1e1e1e] rounded-lg' onChange={handleInputChange} value={(limit)}/>
                          <img src={ arrLeft } onClick={handleDecrease} className=' left-2 top-2 absolute' alt="" />
                          <img src={ arrRight } onClick={handleIncrease} className=' right-2 top-2 absolute' alt="" />
                        </div>
                    </label>
                    <button onClick={startOrder} className=' w-full bg-[#2A49F9] h-[45px] mt-3 rounded-md capitalize font-Anuphan font-medium text-lg'>{buySellButton}</button>
                    </div>
                    <div className=' w-[39%]'>
                    <div className=' w-full'>
                        <table className=' w-full px-1'>
                            <thead>
                            <tr>
                                <th className=' text-base font-Anuphan text-[#ffffffcc] font-medium text-left'>Price</th>
                                <th className=' text-base font-Anuphan text-[#ffffffcc] font-medium text-right'>Quantity</th>
                            </tr>
                            </thead>
                            <tbody className=' text-sm font-Anuphan font-normal'>
                            {price1.map((price, index) => (
                                <tr key={index}>
                                <td className=' text-left text-red-400'>{price}</td>
                                <td className=' text-right'>{quantity1[index]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                        <div className=' w-full mt-3'>
                            <input type="text" value={mockAmount} className=' w-full text-sm font-Anuphan font-medium p-1.5 border text-center border-[#ffffff3b] rounded-lg bg-transparent' />
                        </div>
                        <div className=' w-full mt-3'>
                        <table className=' w-full px-1'>
                            <thead>
                            <tr>
                                <th className=' text-base font-Anuphan text-[#ffffffcc] font-medium text-left'>Price</th>
                                <th className=' text-base font-Anuphan text-[#ffffffcc] font-medium text-right'>Quantity</th>
                            </tr>
                            </thead>
                            <tbody className=' text-sm font-Anuphan font-normal'>
                            {price2.map((price, index) => (
                                <tr key={index}>
                                <td className=' text-left text-[#30B278]'>{price}</td>
                                <td className=' text-right'>{quantity2[index]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          {pnlComp && <div id="overflow" className=' w-full px-3 pt-3 md:px-6'>
            <div className='border-b mb-3 border-[#cbcbcb6c] pb-3'>
              <span className=' flex items-center space-x-3'>
                <button className=' w-4 h-4 bg-[#2A49F9] font-Anuphan text-xs font-semibold'>B</button>
                <p className='font-Anuphan font-semibold text-base flex flex-row uppercase'>{tradeName}/USD</p>
              </span>
              <div className=' w-full flex justify-between mt-2'>
                <span className=' flex flex-col'>
                  <p className=' font-Anuphan text-sm font-medium text-[#ffffff59] underline'>PnL(USD)</p>
                  <p className={`font-Anuphan text-sm font-medium ${tradeState === 'loss' ? 'text-red-500' : 'text-[#30B278]'}`}>{profitNloss.toFixed(2)}</p>
                </span>
                <span className=' flex flex-col text-right'>
                  <p className=' font-Anuphan text-sm font-medium text-[#ffffff59] underline'>ROE</p>
                  <p className={`font-Anuphan text-sm font-medium ${tradeState === 'loss' ? 'text-red-500' : 'text-[#30B278]'}`}>
                        {tradeState === 'gain' ? `+${percentage.toFixed(2)}%` : `-${percentage.toFixed(2)}%`}
                  </p>
                </span>
              </div>
              <div className=' w-full flex justify-between mt-2'>
                <span className=' flex flex-col'>
                  <p className=' font-Anuphan text-sm font-medium text-[#ffffff59] underline'>Margin(USD)</p>
                  <p className=' font-Anuphan text-sm font-medium text-[#fff]'>{percentagePrice}</p>
                </span>
                <span className=' flex flex-col text-right'>
                  <p className=' font-Anuphan text-sm font-medium text-[#ffffff59] underline'>Risk</p>
                  <p className=' font-Anuphan text-sm font-medium text-[#30B278]'>10.47%</p>
                </span>
              </div>
              <div className=' w-full flex justify-between mt-2'>
                <span className=' flex flex-col'>
                  <p className=' font-Anuphan text-sm font-medium text-[#ffffff59] underline'>Entry Price(USD)</p>
                  <p className=' font-Anuphan text-sm font-medium text-[#fff]'>{mockAmount}</p>
                </span>
                <span className=' flex flex-col'>
                  <p className=' font-Anuphan text-sm font-medium text-[#ffffff59] underline'>Mark Price(USD)</p>
                  <p className=' font-Anuphan text-sm font-medium text-[#fff]'>{limit}</p>
                </span>
                <span className=' flex flex-col text-right'>
                  <p className=' font-Anuphan text-sm font-medium text-[#ffffff59] underline'>Liq. Price(USD)</p>
                  <p className=' font-Anuphan text-sm font-medium text-[#fff]'>{formattedLiquid}</p>
                </span>
              </div>
              <div className=' w-full flex justify-between my-2'>
                <button onClick={stopUpdating} className=" w-full bg-[#1e1e1e] rounded-md font-Anuphan py-1">Close Position</button>
              </div>
            </div>
          </div>}
        </div>
      </motion.div>}
            {ineliPopup && <div className="w-[100%] h-[100vh] bg-[rgba(0,0,0,0.8)] fixed top-0 left-0 flex justify-center items-center px-5 z-[1000]">
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setIneliPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className="py-8 px-5 space-y-3 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                        <img src={ failed } className=" w-10 h-10" alt="" />
                        <p className=" font-Anuphan text-base font-medium text-[#a7a7a7]">We are Sorry, You are Ineligible to Withdraw at this Time.</p>
                        {status === 'upgrade' && <p className="font-Anuphan text-sm font-medium text-[#fff]">Kindly Upgrade your Account to Continue</p>}
                        { status === 'withdraw' && <p className="font-Anuphan text-sm font-medium text-[#fff]">You need to Pay a Withdrawal Fee</p>}
                        { status === 'commission' && <p className="font-Anuphan text-sm font-medium text-[#fff]">You need to Pay a commision Fee</p>}
                        <p className="font-Anuphan text-sm font-medium text-[#a7a7a7] capitalize">Contact our live support for more information</p>
                    </div>
                </div>
                </div>}
            {/* { qrPopup && <div className="  w-[100%] h-[100vh] z-[9999999999999] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5 overflow-y-auto">
                <div className=" w-full md:w-[400px] h-[400px] bg-[#1e1e1e] flex justify-center items-center relative">
                    <img src={plus} onClick={()=>{setQrPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    { Qrbtc && <img src= { btcImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qreth && <img src= { ethImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qrdoge && <img src= { dogeImg } className=" w-[300px] h-[300px]" alt="" />}
                    { Qrusdt && <img src= { usdtImg } className=" w-[300px] h-[300px]" alt="" />}
                </div>
                </div>} */}
            { depoPopup && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5 overflow-y-auto'>
                <div className=" h-[90vh]">
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setDepoPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className=" py-8 px-5 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                <p className=" font-Anuphan text-base font-medium">Send Your Deposit to One Of The Accounts Below</p>
                <div className=" space-y-3 w-full md:w-[450px] flex flex-col justify-center items-center mt-3">
                    <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Bitcoin Address
                        <input type="text" readOnly value={walletAddy.btc} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] text-xs md:text-sm focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getBtcQr } id="btc" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Btc Qr Code</button> 
                    </label>
                    <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Ethereum Address
                        <input type="text" readOnly value={walletAddy.eth} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] text-xs md:text-sm focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getEthQr } id="Eth" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Eth Qr Code</button> 
                    </label>
                    <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Doge Address
                        <input type="text" readOnly value={walletAddy.doge} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] text-xs md:text-sm focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getDogeQr } id="doge" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Doge Qr Code</button> 
                    </label>
                    <label className=" relative w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        USDT TRC
                        <input type="text" readOnly value={walletAddy.usdt} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] text-xs md:text-sm focus:border-0 active:border-0 rounded-md mt-1"  />
                        <img src={ copy } onClick={ handleCopyClick } className=' w-4 h-4 absolute top-9 right-3' alt="" />
                        <button onClick={ getUsdtQr } id="Usdt" className=" block mr-auto mt-2 px-2 rounded-md shadow py-1 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">Usdt Qr Code</button> 
                    </label>
                    <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Amount
                        <input type="text" onKeyUp={ handleAmount } className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Upload Proof of Payment
                        <input onChange={ handleChange } type="file" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px]  focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        <p className='font-Anuphan text-xs'>For your convenience, we offer bank transfer as a payment option. To obtain further details, please reach out to our live customer support.</p>
                    </span>
                    <p id='confirm' className='hidden mr-auto font-Anuphan text-xs text-green-600 capitalize font-medium'>*Your transaction has been initiated but is awaiting confirmation</p>
                    <button onClick={ sumbmitImg } className=" block ml-auto px-2 rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                        { loading === 'No' && 'Submit'}
                        { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                    </button>
                </div>
                </div>
            </div>
                </div>
            </div>}
            { withdrawPopup && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 flex justify-center items-center px-5'>
                <div className="w-full md:w-[600px] rounded-md shadow bg-[#1e1e1e] relative">
                    <img src={plus} onClick={()=>{setWithdrawPopup(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <div className="py-8 px-5 md:px-5 md:py-5 mt-5 flex flex-col justify-center items-center w-full">
                        <p className="font-Anuphan text-base font-medium">Select Your Preferred Withdrawal Method</p>
                        <div className=" w-full flex flex-col font-Anuphan justify-center items-center text-sm font-normal mt-3 text-[rgba(255,255,255,0.8)]">
                            <select name="" onChange={ withdrawalMethod } id="payMethod" type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" >
                                <option value="">Select Withdrawal Method</option>
                                <option value="btc">Bitcoin(Recommended)</option>
                                <option value="pal">Paypal</option>
                                <option value="wu">Western Union</option>
                            </select>
                        </div>
                        <div id="btcMeth" className=" w-full md:w-[450px] mt-3 space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input onChange={(e)=>{setWdAmount(e.target.value)}} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Wallet Address
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                        </div>
                        <div id="ppMeth"  className=" mt-3 w-full md:w-[450px] space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input onChange={(e)=>{setWdAmount(e.target.value)}} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Paypal Mail/Address
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>    
                        </div>
                        <div id="wuMeth" className=" mt-3 w-full md:w-[450px] space-y-3 hidden">
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Amount
                                <input onChange={(e)=>{setWdAmount(e.target.value)}} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                            <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                                Account Number
                                <input type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                            </label>
                           
                        </div>
                        <p id="btcWarn" className=" md:ml-[52px] mr-auto mt-2 font-Anuphan text-xs font-medium text-red-600 hidden">Insufficient Balance</p>
                        <button onClick={ btcBalCheck } className="px-2 md:mr-[52px] mt-3 block ml-auto rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                            { wdLoading === 'No' && 'Submit'}
                            { wdLoading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                    </div>
                </div>
            </div>}
            <div className="absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
                <div className="relative space-y-6  lg:space-y-0">
                    <div className=' flex flex-col md:flex-row justify-between space-y-6 md:space-y-0'>
                        <div className=" w-full lg:w-[500px] h-[200px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-5 flex flex-col relative">
                            <span className=" flex flex-row items-center space-x-2">
                                <button className="flex justify-center items-center w-[40px] h-[40px] bg-[#2A49F9] rounded-xl shadow-md">
                                    <img src={ crypto } alt="" />
                                </button>
                                <p className=' font-Anuphan text-sm text-[#fff] font-medium'>Cryptocurency</p>
                            </span>
                            <div className=" flex flex-row justify-between">
                                <span className=' flex flex-col mt-2'>
                                    <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Total Balance</p>
                                    <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.CryptoTotalbal+'.00'}</p>
                                </span>
                                <div className=" flex justify-end items-end space-x-5">
                                    <span className="text-center flex flex-col items-center">
                                    <button onClick={()=>{setDepoPopup(true)}}  className=" w-[40px] h-[40px] rounded-[50%] bg-[rgba(219,224,255,0.2)] backdrop-blur-[15px] flex justify-center items-center">
                                        <img src={ withdraw } className=" rotate-180" alt="deposit" />
                                    </button>
                                    <p className=" font-Anuphan text-xs text-[#a7a7a7] font-medium">Deposit</p>
                                    </span>
                                    <span className=" text-center flex flex-col items-center">
                                    <button onClick={()=>{setWithdrawPopup(true)}} className=" w-[40px] h-[40px] rounded-[50%] bg-[rgba(219,224,255,0.2)] backdrop-blur-[15px] flex justify-center items-center">
                                        <img src={ withdraw } alt="withdraw" />
                                    </button>
                                    <p className=" font-Anuphan text-xs text-[#a7a7a7] font-medium">Withdraw</p>
                                    </span>
                                </div>
                            </div>
                            <div className=' absolute bottom-5 flex flex-row w-full justify-between pr-9'>
                                <span className=' flex flex-col'>
                                    <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Deposit</p>
                                    <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.CryptoDeposit+'.00'}</p>
                                </span>
                                <span className=' flex flex-col'>
                                    <p className=' font-medium font-Anuphan text-[#a7a7a7] text-xs'>Profits</p>
                                    <p className=' font-medium font-Anuphan text-[#fff] text-sm'>{'$'+userData.CryptoProfits+'.00'}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="analysis" className=" lg:absolute right-0 top-[440px] w-full lg:w-[450px] h-[120px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col overflow-hidden">
                        <ETHUSDTWidget/>
                    </div>
                    <div id="analysis" className=" lg:absolute right-0 top-0 w-full lg:w-[450px] h-[420px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col overflow-hidden">
                        <AnalysisWidget />
                    </div>
                    <div className=" lg:absolute left-0 top-[230px] w-full lg:w-[500px] h-[330px] rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 flex flex-col overflow-hidden">
                        <OverviewCrypto/>
                    </div>
                    { (userData.plan === 'Master-plan' || userData.plan === 'Vip-plan') && <div className=" lg:absolute mb-3 left-0 top-[580px] w-full rounded-md shadow-sm border border-[rgba(255,255,255,0.0)] overflow-auto bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3  flex flex-col ">
                        <div className=''>
                            <table className=' w-[100%] font-Anuphan text-sm text-white font-normal'>
                                <thead>
                                    <tr className=' border-b border-[#EAEBF01A]'>
                                        <th className=' text-left font-Anuphan font-medium text-xs md:text-sm py-5 pl-2'>Crypto</th>
                                        <th className=' text-left font-Anuphan font-medium text-xs md:text-sm py-5 '>Current Price</th>
                                        <th className=' text-left font-Anuphan font-medium text-xs md:text-sm py-5 md-max:hidden'>Low (24h)</th>
                                        <th className=' text-left font-Anuphan font-medium text-xs md:text-sm py-5 md-max:hidden'>High (24h)</th>
                                        <th className=' text-left font-Anuphan font-medium text-xs md:text-sm py-5 '>Price Change (24h)</th>
                                        <th className=' text-left font-Anuphan font-medium text-xs md:text-sm py-5 '>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='font-Anuphan text-sm text-white font-normal'>
                                {dataSet.map((crypto, index) => (
                                    <tr className=' space-x- border-b border-[#EAEBF01A]' key={index}>
                                    <td className=''>
                                    <div className="crypto-info flex flex-row py-4 ml-2 space-x-2 items-center">
                                        <img
                                            src={crypto.image}
                                            alt={`${crypto.name}`}
                                            className=' w-[20px] h-[20px] lg:w-[32px] lg:h-[32px] rounded-[50%]'
                                        />
                                        <p className=' font-Anuphan font-normal text-xs md:text-sm uppercase'>{crypto.symbol}</p>
                                    </div>
                                    </td>
                                    <td className='font-Anuphan text-xs md:text-sm py-4  text-white font-normal' >${crypto.current_price}</td>
                                    <td className=' font-Anuphan text-xs md:text-sm py-4 md-max:hidden text-white font-normal'>${crypto.low_24h}</td>
                                    <td className=' font-Anuphan text-xs md:text-sm py-4 md-max:hidden text-white font-normal'>${crypto.high_24h}</td>
                                    <td className={getPriceChangeClass(crypto.price_change_percentage_24h)}>{crypto.price_change_percentage_24h}%</td>
                                    <td className=''><button onClick={ getTradeinfo } className=' bg-[#2A49F9] rounded-[20px] px-4 py-2 font-Anuphan font-normal text-xs md:text-sm'>Trade</button></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
     );
}
 
export default CryptoDash;