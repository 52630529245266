import udl from "../images/udl.png";
import jdl from "../images/jdl.png";
import Footer from "../comps/footer";
import Navbar from "../comps/navbar";
import { motion } from "framer-motion";

const Terms = () => {
  return (
    <>
      <Navbar />
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -100, opacity: 0 }}
        transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
      >
        <img
          src={udl}
          className=" absolute  md:block top-0 left-0 -z-[99999] w-[200px] h-[200px] blur-2xl backdrop-blur-xl"
          alt=""
        />
        <div className=" mt-[70px] p-5 md:p-10 space-y-2 relative">
          <img
            src={jdl}
            className=" absolute bottom-0 md:-bottom-[7%] right-0 -z-[99999] w-[250px] h-[250px] blur-2xl backdrop-blur-xl"
            alt=""
          />
          <p className=' text-lg md:text-2xl font-bold text-center font-Anuphan capitalize"'>
            Terms & Condition
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            General Terms
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            By accessing and placing an order with, you confirm that you are in
            agreement with and bound by the terms of service contained in the
            Terms & Conditions outlined below. These terms apply to the entire
            website and any email or other type of communication between you and
            under no circumstances shall team be liable for any direct,
            indirect, special, incidental or consequential damages, including,
            but not limited to, loss of data or profit, arising out of the use,
            or the inability to use, the materials on this site, even if team or
            an authorized representative has been advised of the possibility of
            such damages. If your use of materials from this site results in the
            need for servicing, repair or correction of equipment or data, you
            assume any need for servicing, repair or correction of equipment or
            data, you assume any costs thereof. And will not be responsible for
            any outcome that may occur during the course of usage of our
            resources. We reserve the rights to change prices and revise the
            resources usage policy in any moment.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            License
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Bitstakepro grants you a revocable, non-exclusive, non-transferable,
            limited license to download, install and use our service strictly in
            accordance with the terms of this Agreement. These Terms &
            Conditions are a contract between you and Bitstakepro (referred to
            in these Terms & Conditions as "Bitstakepro", "us", "we" or "our"),
            the provider of the Bitstakepro website and the services accessible
            from the Bitstakepro website.(which are collectively referred to in
            these Terms & Conditions as the "Bitstakepro Service"). You are
            agreeing to be bound by these Terms & Conditions. If you do not
            agree to these Terms & Conditions, please do not use the Service. In
            these Terms & Conditions, "you" refers both to you as an individual
            and to the entity you represent. If you violate any of these Terms &
            Conditions, we reserve the right to cancel your account or block
            access to your account without notice.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            Definitions and Key Terms
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Cookie: small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, remember information about you such as your language
            preference or login information
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Company: when this policy mentions "Company," "we," "us," or "our,"
            it refers to Bitstakepro that is responsible for your information
            under this Privacy Policy.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Country: where Bitstakepro or the owners/founders of Bitstakepro are
            based, in this case is America.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Customer: refers to the company, organization or person that signs
            up to use the Bitstakepro Service to manage the relationships with
            your consumers or service users.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Device: any internet connected device such as a phone, tablet,
            computer or any other device that can be used to visit Bitstakepro
            and use the services.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            IP address: Every device connected to the Internet is assigned a
            number known as an Internet protocol (IP) address. These numbers are
            usually assigned in geographic blocks. An IP address can often be
            used to identify the location from whic device is connecting to the
            Internet
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            {" "}
            Personal Data: any information that directly, indirectly, or in
            connection with other information - including a personal
            identification number - allows for the identification or
            identifiability of a natural person.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            {" "}
            Service: refers to the service provided by Bitstakepro as described
            in the relative terms (if available) and on this platform.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Third-party service: refers to advertisers, contest sponsors,
            promotional and marketing partners, and others who provide our
            content or whose products or services we think may interest you.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Website: Bitstakepro's site, which can be accessed via this URL:
            www.Bitstakepro.com.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            You: a person or entity that is registered with Bitstakepro to use
            the Services.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            Restrictions
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            You agree not to, and you will not permit others to:
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            License, sell, rent, lease, assign, distribute, transmit, host,
            outsource, disclose or otherwise commercially exploit the service or
            make the platform available to any third party.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Modify, make derivative works of, disassemble, decrypt, reverse
            compile or reverse engineer any part of the service.
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Remove, alter or obscure any proprietary notice (including any
            notice of copyright or trademark) of or its affiliates, partners,
            suppliers or the licensors of the service.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            Your Suggestions
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Any feedback, comments, ideas, improvements or suggestions
            (collectively, "Suggestions") provided by you to us with respect to
            the service shall remain the sole and exclusive property of us. We
            shall be free to use, copy, modify, publish, or redistribute the
            Suggestions for any purpose and in any way without any credit or any
            compensation to you.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            Your Consent
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            We've updated our Terms & Conditions to provide you with complete
            transparency into what is being set when you visit our site and how
            it's being used. By using our service, registering an account, or
            making a purchase, you hereby consent to our Terms & Conditions.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            Link to other Sites
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            Our service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Terms & Conditions of every site You visit. We have no
            control over and assume no responsibility for the content, Terms &
            Conditions or practices of any third party sites or services
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            We use "Cookies" to identify the areas of our website that you have
            visited. A Cookie is a small piece of data stored on your computer
            or mobile device by your web browser. We use Cookies to enhance the
            performance and functionality of our service but are non-essential
            to their use. However, without these cookies, certain functionality
            like videos may become unavailable or you would be required to enter
            your login details every time you visit our platform as we would not
            be able to remember that you had logged in previously. Most web
            browsers can be set to disable the use of Cookies. However, if you
            disable Cookies, you may not be able to access functionality on our
            website correctly or at all. We never place Personally Identifiable
            Information in Cookies. Changes To Our Terms & Conditions
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            You acknowledge and agree that we may stop (permanently or
            temporarily) providing the Service (or any features within the
            Service) to you or to users generally at our sole discretion,
            without prior notice to you. You may stop using the Service at any
            time. You do not need to specifically inform us when you stop using
            the Service. You acknowledge and agree that if we disable access to
            your account, you may be prevented from accessing the Service, your
            account details or any files or other materials which is contained
            in your account. If we decide to change our Terms & Conditions, we
            will post those changes on this page, and/or update the Terms &
            Conditions modification date below. Modifications to Our service We
            reserve the right to modify, suspend or discontinue, temporarily or
            permanently, the service or any service to which it connects, with
            or without notice and without liability to you.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            Update to our Services
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            We may from time to time provide enhancements or improvements to the
            features/functionality of the service, which may include patches,
            bug fixes, updates, upgrades and other modifications ("Updates").
            Updates may modify or delete certain features and/or functionalities
            of the service.You agree that we have no obligation to (i) provide
            any Updates, or (ii) continue to provide or enable any particular
            features and/or functionalities of the service to you. You further
            agree that all Updates will be (i) deemed to constitute an integral
            part of the service, and (ii) subject to the terms and conditions of
            this Agreement.
          </p>
          <p className="text-lg text-[#fff] font-medium font-Anuphan capitalize">
            Third Party Services
          </p>
          <p className="text-sm text-[#9FA3AB] font-normal font-Anuphan mt-1">
            We may display, include or make available third-party content
            (including data, information, applications and other products
            services) or provide links to third-party websites or services
            ("Third Party Services"). You acknowledge and agree that we shall
            not be responsible for any Third-Party Services, including including
            their accuracy, completeness, timeliness, validity, copyright
            compliance, legality, decency, quality or any other aspect thereof.
            We do not assume and shall not have any liability or responsibility
            to you or any other person or entity for any Third Party Services.
            Third-Party Services and links thereto are provided solely as a
            convenience to you and you access and use them entirely at your own
            risk and subject to such third parties' terms and conditions. Term
            and Termination This Agreement shall remain in effect until
            terminated by you or us. We may, in its sole discretion, at any time
            and for any or no reason, suspend or terminate this Agreement with
            or without prior notice. This Agreement will terminate immediately,
            without prior notice from us, in the event that you fail to comply
            with any provision of this Agreement. You may also terminate this
            Agreement by deleting the service and all copies thereof from your
            computer. Upon termination of this Agreement, you shall cease all
            use of the service and delete all copies of the service from your
            computer. Termination of this Agreement will not limit any of our
            rights or remedies at law or in equity in case of breach by you
            (during the term of this Agreement) of any of your obligations under
            the present Agreement. No Warranties The service is provided to you
            "AS IS" and "AS AVAILABLE" and with all faults and defects without
            warranty of any kind. To the maximum extent permitted under
            applicable law, we, on our own behalf and on behalf of our
            affiliates and our respective licensors and service providers,
            expressly disclaims all warranties, whether express, implied,
            statutory or otherwise, with respect to the service, including all
            implied warranties of merchantability, fitness for a particular
            purpose, title and non-infringement, and warranties that may arise
            out of course of dealing, course of performance, usage or trade
            practice. Without limitation to the foregoing, we provide no
            warranty or undertaking, and makes no representation of any kind
            that the service will meet Without limiting the foregoing, neither
            us nor any provider makes any representation or warranty of any
            kind, express or implied: (i) as to the operation or availability of
            the service, or the information, content, and materials or products
            included thereon; (ii) that the service will be uninterrupted or
            error-free; (iii) as to the accuracy, reliability, or currency of
            any information or content provided through the service; or (iv)
            that the service, its servers, the content, or e mails sent from or
            on behalf of us are free of viruses, scripts, trojan horses, worms,
            malware, timebombs or other harmful components. Some jurisdictions
            do not allow the exclusion of or limitations on implied warranties
            or the limitations on the applicable statutory rights of a consumer,
            so some or all of the above exclusions and limitations may not apply
            to you. Limitation of Liability Notwithstanding any damages that you
            might incur, the entire liability of us and any of our suppliers
            under any provision of this Agreement and your exclusive remedy for
            all of the foregoing shall be limited to the amount actually paid by
            you for the service. To the maximum extent permitted by applicable
            law, in no event shall we or our suppliers be liable for any
            special, incidental, indirect, or consequential damages whatsoever
            (including, but not limited to, damages for loss of profits, for
            loss of data or other information, for business interruption, for
            personal injury, for loss of privacy arising out of or in any way
            related to the use of or inability to use the service, third-party
            software and/or third-party hardware used with the service, or
            otherwise in connection with any provision of this Agreement).
          </p>
        </div>
        <Footer />
      </motion.div>
    </>
  );
};

export default Terms;
