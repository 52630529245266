import { useContext } from "react";
import { FeatBuyContext, FeatureCycle,  FeatureInfo,  FeaturePrice,  FeatureProfit,  FeaturedName } from "../contexts/pageContext";
import { motion } from "framer-motion";



const StocksTable = () => {

    const [featBuy, setFeatBuy] = useContext(FeatBuyContext);
    const [featName, setFeatName] = useContext(FeaturedName);
    const [featCycle, setFeatCycle] = useContext(FeatureCycle);
    const [featPrice, setFeatPrice] = useContext(FeaturePrice);
    const [featProfit, setFeatProfit] =  useContext(FeatureProfit);
    const [featInfo, setFeatInfo] = useContext(FeatureInfo);

    const handleBuyClick = e =>{
        setFeatBuy(true);
        const parent = e.target.parentElement;
        const parentParent = parent.parentElement;
        const elements = parentParent.children;
        const name = elements[0].innerHTML;
        const price = elements[1].innerHTML;
        const profit = elements[2].innerHTML;
        const info = elements[3].innerHTML;
        const cycle = elements[4].innerHTML;
        setFeatName(name);
        setFeatPrice(price);
        setFeatProfit(profit);
        setFeatInfo(info);
        setFeatCycle(cycle);
    }

    return ( 
        <>
        <table className="border-collapse border-b w-full border-[rgba(255,255,255,0.2)] mt-3">
            <thead>
                <tr className=" text-[#a7a7a7] text-sm font-Anuphan font-medium">
                <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Stock Name</th>
                <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Minimum selling($)</th>
                <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Profit</th>
                <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Cycle</th>
                <th className="p-2 border-b border-[rgba(255,255,255,0.2)]"></th>
                </tr>
            </thead>
            <tbody className=" font-Anuphan text-sm font-normal">
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">L&K CBD</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">5000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">15.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden"></td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Weekly</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Operatrades Forex Trade(AFXT)</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">500</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">1.10%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">Alstonglobal Forex Day Trading (AFXDT) a Roy.j.Camillaâ€™s forex trade group.This group consist of different traders employed by Alstonglobal for a common goal of making good return on investment for the company and its investors. This group was found in 2012 as a Roy.j.Camilla private trading company, trading for him and his investor colleagues until 2020 when the company was integrated into a public limited liability company.Thereafter,it went public and globally for investors to invest for the collective aim of controlling the market using its strength of capital comparative as advantage to manipulate global forex market for the benefit of its investors. The Alstonglobal forex trading company current market capitalization is over 665 million dollar.It's a great company to buy into as it's projected to hit 1 billion dollar market capitalization before the end of 2021. As its market capitalization increases so would return on investment(ROI) increase for its investors. Minimum investment-$200 Contract duration-1year Withdrawals-Saturday & Sundayâ€™s Return on investment(ROI)-1.10% working days</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Weekly</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Archer-Daniels Midland Company(ADM)</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">5000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">35.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">The Archer-Daniels-Midland Company, commonly known as ADM, is an American multinational food processing and commodities trading corporation which produces Corn Syrup, High fructose corn syrup, Feed, Ethanol and Bioenergy. ADM was found in 1902 and headquartered in Chicago, Illinois. Stock price: ADM (NYSE) US$58.25 CEO: Juan Ricardo Luciano Headquarters: Chicago, Illinois. ADM was Founded 1902, Minneapolis, Minnesota, United States. ADM currently at $64.66 billion USD market capitalization. Subsidiaries: ADM Investor Services, Inc., Wild, Archer Daniels Midland Singapore Pte Ltd. Founders: George A. Archer and John W. Daniels. Investment features: * 35% ROI everyone 3months * 1year contract duration with automatic reinvestment. * Minimum investment $5000 * profit withdrawal every 3months</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] ">Three Months</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">BAYN</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">10000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">1.60%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">BAYN is DAX Component Industry which deals in Life sciences and Pharmaceuticals Chemicals BAYN was Founded 1 August 1863 by Friedrich Bayer. BAYN is Headquartered in Leverkusen, Germany. Chief executive officer(CEO)-Werner Baumann.</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Daily</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Diamond Shipping Group Inc.(DSD)</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">15000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">50.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">Diamond Shipping Group Inc. (DSG) is a shipping company legally incorporated in the Marshall Islands in 2007 with headquarters in Greenwich, Connecticut, United States .</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Three Months</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Clean Energy Fuels Corp.(CEF)
</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">25000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">70.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">Clean Energy Fuels Corp.founded in 1997,Clean Energy Fuels is a producer of renewable natural gas. That means they create natural gas from decomposing organic waste, rather than extracting it from underground deposits.</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Six Months</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Double Hall Tankers Inc.(DHT)
</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">20000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">2.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">Double hall Tankers inc. (DHT)founded in 2005 headquartered in Bermuda DHT was formed and listed on the New York Stock Exchange in 2005.DHT Holdings, Inc. is an independent crude oil tanker company. The Companyâ€™s fleet trades internationally and consists of 27 crude oil tankers in the very large crude carrier (VLCC) segment. The Company has all functions in-house and operates through integrated management companies in Monaco, Singapore and Oslo, Norway. Svein Moxnes Harfjeld and Trygve Preben Munthe Are Co-Chief Executive Of the company, the company boasts of a market capitalization of $USD975.3million dollars.</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Working Days</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Helix Energy Solutions Inc(HES)
</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">40000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">35.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">Helix Energy Solutions Inc., known as Cal Dive International prior to 2006,it is an American oil and gas services company headquartered in Houston, Texas. It was founded in 1980, the company is a global provider of offshore services in well intervention</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Monthly</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">QEP Resources Inc.(WEPR)</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">50000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">48.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">QEP Resources Inc QEP Resources, Inc. operates as an independent natural gas, oil exploration, and production company. The Company has its headquarters in Denver, Colorado United States. The company was founded in 1922,QEP also gathers, compresses, and treats.</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Three Months</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick } className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
                <motion.tr
                initial={{scaleX:1}}
                whileHover={{scaleX:1.03}}
                >
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Scottâ€™S Miracle-Gro Company(SMG)
</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">5000</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] text-green-700">80.00%</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)] hidden">The Scotts Miracle-Gro Company is an American multinational corporation headquartered in Marysville, Ohio, founded by Orlando M. Scott in 1868 as a premium seed company for the U.S. agricultural industry.</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">Six Months</td>
                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]"><button onClick={ handleBuyClick }   className=" px-2 py-1 rounded-md shadow-md bg-[#] text-white text-center">Buy</button></td>
                </motion.tr>
            </tbody>
        </table>
        </>
     );
}
 
export default StocksTable;