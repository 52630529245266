import udl from '../images/udl.png';
import use from '../images/use.png';
import stat from '../images/stat.png';
import lock from '../images/lock.png';
import cyc from '../images/cyc.png';
import obj from '../images/obj.png';
import { motion } from 'framer-motion';


const Why = () => {
    return ( 
        <>
        <motion.div 
        initial={{y:200, opacity:0}}
        whileInView={{y:0, opacity:1}}
        transition={{type:'tween', duration:0.5, delay:0.3}}
        className=' px-5 pb-10 md:px-12 mt-12 md:pb-16 relative'>
            <img src={ udl } className=' absolute bottom-0 left-0 -z-[99999] w-[250px] h-[250px] blur-2xl backdrop-blur-xl' alt="" />
            <div className=' flex flex-col lg:flex-row justify-between space-y-[22px] lg:space-y-0'>
                <div className=' flex flex-col space-y-[22px] lg:mr-10'>
                    <div className=' h-[250px] p-6 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px]'>
                        <img src={ use } className=' w-[50px] h-[50px]' alt="" />
                        <p className='font-medium font-Anuphan text-base mt-3'>Personal account manager</p>
                        <p className=' font-thin font-Anuphan text-sm mt-3 lg:w-[250px]'>our account managers are dedicated to providing individualized support and guidance to clients, helping them manage their investment portfolios and navigate the financial markets</p>
                    </div>
                    <div className=' h-[250px] p-6 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px]'>
                        <img src={ lock } className=' w-[50px] h-[50px]' alt="" />
                        <p className='font-medium font-Anuphan text-base mt-3 leading-5'>Free access to forex<br></br> market research</p>
                        <p className=' font-thin font-Anuphan text-sm mt-3 lg:w-[250px]'>Gain valuable insights and stay ahead of the curve with our free access to forex market research</p>
                    </div>
                </div>
                <div className='  h-[522px] p-6 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px]'>
                    <p className=' font-medium font-Anuphan text-lg'>24/7 hours customer care</p>
                    <p className=' font-thin font-Anuphan text-[16px] mt-3 '>Our customer care team is available 24/7 to assist you with any questions or issues you may have. Whether it's in the middle of the night or on a holiday, you can rely on us to be there for you. </p>
                    <div className=' w-full flex justify-center mt-8'>
                        <img src= { obj } className=' w-[323px] h-[300px]' alt="" />
                    </div>
                </div>
                <div className=' flex flex-col space-y-[22px] lg:ml-10'>
                    <div className=' h-[250px] p-6 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px]'>
                        <img src={ stat } className=' w-[50px] h-[50px]' alt="" />
                        <p className='font-medium font-Anuphan text-base mt-3 leading-5'>Daily access to forex<br></br> trading signal</p>
                        <p className=' font-thin font-Anuphan text-sm mt-3 lg:w-[250px]'>DLorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat nulla suspendisse tortor aene.</p>
                    </div>
                    <div className=' h-[250px] p-6 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px]'>
                        <img src={ cyc } className=' w-[50px] h-[50px]' alt="" />
                        <p className='font-medium font-Anuphan text-base mt-3 leading-5'>Trading Bots</p>
                        <p className=' font-thin font-Anuphan text-sm mt-3 lg:w-[250px]'>With real-time trading, traders can make quick decisions based on current market conditions and execute trades immediately.</p>
                    </div>
                </div>
            </div>
        </motion.div>
        </>
     );
}
 
export default Why;