import Contact from "./pages/contact";
import Home from "./pages/home";
import Terms from "./pages/terms";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Signup from "./pages/signup";
import Signin from "./pages/signin";
import Dashboard from "./pages/dashboard";
import PasswordReset from "./pages/passwordReset";

function App() {
  const location = useLocation();
  

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path = "/" element={ <Home/> } />
        <Route path = "/contact" element = { <Contact/> }/>
        <Route path = "/terms" element = { <Terms/> }/>
        <Route path="/Signup" element = { <Signup/> }/>
        <Route path="/Signin" element = { <Signin/> }/>
        <Route path="/dashboard" element = { <Dashboard/> }/>
        <Route path="/Reset-Password" element = { <PasswordReset/>}/>
      </Routes>
    </AnimatePresence>
  );
}

export default App;
