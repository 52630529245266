import { useContext, useState, useRef, useEffect } from 'react';
import cloud from '../images/cloud.svg';
import load from '../images/load.gif';
import {  UserInfo, PageContext } from '../contexts/pageContext';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, addDoc, query, where, onSnapshot} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Profile = () => {
    const [userData, setUserData] = useContext(UserInfo);
    const [userInfo, setUserinfo] = useState([]);
    const [page, setPage] = useContext(PageContext);
    const [activeButton, setActiveButton] = useState('MyDetails');
    const [detail, setDetail] = useState(true);
    const [verification, setVerification] = useState(false);
    const [selectedFileName1, setSelectedFileName1] = useState('');
    const [selectedFileName2, setSelectedFileName2] = useState('');
    const [loading, setLoading] = useState('No');
    const [loading1, setLoading1] = useState('No');
    const [loading2, setLoading2] = useState('No');
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [idType, setidType] = useState('ID');
    const [file1url, setFile1url] = useState('');
    const [file2url, setfile2url] = useState('');
    const [myAddy, setmyWalletAddy] = useState('');
    const [crypto, setCrypto] = useState('');
    
    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };

    useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
              const uid = user.uid;
              const colRef = collection(db, 'paymentmethod');
              const q = query(colRef, where('uid', '==', uid))
              onSnapshot(q,(snapshot)=>{
                snapshot.docs.forEach((doc)=>{
                    setUserinfo(doc.data())
                })
              })
            }else{
            }
        });
    },[page]);

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const auth = getAuth();
    const storage = getStorage(app);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    //date and time
    // For todays date;
    Date.prototype.today = function () { 
        return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
    }

    // For the time now
    Date.prototype.timeNow = function () {
        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
    }
    
    const newDate = new Date();
    const date =  newDate.today();
    const time = newDate.timeNow();

    //document data
    const userName = userData.name;
    const userUid = userData.uid;
    const imageName1 = userName + selectedFileName1;
    const imageName2 = userName + selectedFileName2;

    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);

    const handleFileInputChange1 = (e) => {
        const selectedFile = e.target.files[0];
        setSelectedFileName1(selectedFile ? selectedFile.name : '');
        setFile1(selectedFile);
    };
    const handleFileInputChange2 = (e) => {
        const selectedFile = e.target.files[0];
        setSelectedFileName2(selectedFile ? selectedFile.name : '');
        setFile2(selectedFile);
    };

    const handleFileInputClick1 = () => {
        fileInputRef1.current.click();
    };
    const handleFileInputClick2 = () => {
        fileInputRef2.current.click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop1 = (e) => {
        e.preventDefault();

        const droppedFile = e.dataTransfer.files[0];
        setSelectedFileName1(droppedFile ? droppedFile.name : '');
        // Do something with the dropped file, such as uploading it to a server
        setFile1(droppedFile);
    };
    const handleDrop2 = (e) => {
        e.preventDefault();

        const droppedFile = e.dataTransfer.files[0];
        setSelectedFileName2(droppedFile ? droppedFile.name : '');
        // Do something with the dropped file, such as uploading it to a server
        setFile2(droppedFile);
    };

    //submit data
    const sumbmitImg1 = ()=>{
        setLoading1('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName1,
        };
        const storageRef = ref(storage, imageName1, metadata);

        uploadBytes(storageRef, file1).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                setFile1url(url)
                setLoading1('No');
                const confirm = document.getElementById('img1');
                confirm.style.display = 'block';
            });
        });
    };

    const sumbmitImg2 = ()=>{
        setLoading2('Yes')
        const metadata = {
            contentType: 'image/jpeg, image/png',
            name: imageName2,
        };
        const storageRef = ref(storage, imageName2, metadata);

        uploadBytes(storageRef, file2).then((snapshot) => {
            getDownloadURL(storageRef)
            .then((url)=>{
                setfile2url(url)
                setLoading2('No');
                const confirm = document.getElementById('img2');
                confirm.style.display = 'block';
            });
        });
    };

    const sumbmitImg = ()=>{
        setLoading('Yes')
        const verificationDoc = collection(db, 'verification');
        const docData = {
            name: userName,
            uid: userUid,
            date: date,
            time: time,
            idType: idType,
            url1: file1url, // URL of the first image
            url2: file2url, // URL of the second image
        }
        addDoc(verificationDoc, docData)
        .then(()=>{
            setLoading('No');
            const confirm = document.getElementById('confirm');
            confirm.style.display = 'block';
        });
    };

    const submitPM = ()=>{
        setLoading('Yes')
        const paymentmethodDoc = collection(db, 'paymentmethod');

        const docData = {
            name: userName,
            uid: userUid,
            wallet: myAddy,
            crypto: crypto,   
        }
        addDoc( paymentmethodDoc, docData)
        .then(()=>{
            setLoading('No');
            const confirm = document.getElementById('confirmer');
            confirm.style.display = 'block';
        });
    };

    

    return ( 
        <div className=" absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] -z-[99]">
            <div className=" flex flex-row border-b w-full border-[#FFFFFF33]">
                <button
                    className={`font-medium font-Anuphan text-sm pb-3 px-5 transition-all ${
                        activeButton === 'MyDetails'
                            ? 'border-b border-[#2A49F9] text-[#2A49F9]'
                            : ''
                    }`}
                    onClick={() => {handleButtonClick('MyDetails');
                     setDetail(true);
                      setVerification(false)
                    }}
                >
                My Details
                </button>

                <button
                className={`font-medium font-Anuphan text-sm pb-3 px-5 transition-all ${
                    activeButton === 'Verification'
                        ? 'border-b border-[#2A49F9] text-[#2A49F9]'
                        : ''
                }`}
                onClick={() => {handleButtonClick('Verification');
                 setDetail(false);
                  setVerification(true)
                }}
                >
                Verification
                </button>
            </div>
            <div className=" p-5 flex flex-col w-full">
                <p className=' w-full text-left font-Anuphan text-base font-meduium'>Your Details</p>
                <p className=' w-full text-left font-Anuphan text-sm mb-[0.8em] font-meduium'>View your personal details here.</p>
                { detail && <div className=" space-y-3 w-full flex flex-col justify-center items-center">
                    <label className=" w-full flex flex-col items-start border-y border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Name
                        <input type="text" readOnly value={userData.name} className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col items-start border-b border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Country
                        <input type="text" readOnly value={userData.country} className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col items-start border-b border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Email
                        <input type="text" readOnly value={userData.mail} className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col items-start border-b border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Phone No.
                        <input type="text" readOnly value={userData.phone} className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col items-start border-b border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Plan
                        <input type="text" readOnly value={ userData.plan } className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col items-start border-b border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Upgrade Status
                        <input type="text" readOnly value={'Pending'} className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <label className=" w-full flex flex-col items-start border-b border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Withdrawal Status
                        <input type="text" readOnly value={'Pending'} className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </label>
                    <div className=' py-[0.6em] border-b w-full border-[#FFFFFF33]'>
                        <p className=' w-full text-left font-Anuphan text-base font-meduium'>Your Preferred Payment Method</p>
                        <p className=' w-full text-left font-Anuphan text-sm mb-[0.8em] font-meduium text-[#ffffffcc]'>Save your Preferred payment method here.</p>
                        <label className=" w-full flex flex-col items-start  border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            Wallet Address
                            <input type="text" defaultValue={userInfo.wallet} onChange={(e)=>{setmyWalletAddy(e.target.value)}} className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </label>
                        <label className=" w-full flex flex-col items-start border-[#FFFFFF33] relative py-3 md:py-6 md:flex-row md:items-center font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            <p className=' md:w-[200px]'>Please indicate the cryptocurrency(Network)</p>
                            <input type="text"defaultValue={userInfo.crypto}  onChange={(e)=>{setCrypto(e.target.value)}} placeholder='E.g..USDT(BEP20)' className=" md:absolute left-[20%] w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </label>
                        <button onClick={ submitPM } className=' px-4 py-2 flex items-center justify-center w-full bg-[#2A49F9] rounded-md font-Anuphan text-sm'>
                            { loading === 'No' && 'Save'}
                            { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                        </button>
                        <p id='confirmer' className=' ml-auto hidden w-full text-left text-[8px] md:text-xs font-Nunito font-medium text-[#0CD16B]'>*Your Payment method has been saved</p>
                    </div>
                </div>}
                { verification && <div className=" w-full">
                <div className=" py-3 md:py-6 border-b pb-3 border-[#FFFFFF1A]">
                    <p className=" text-[#fff] font-Anuphan font-medium text-lg">Verification details</p>
                    <p className=" font-Anuphan text-base font-normal">Verify your account by uploading the required document here.</p>
                </div>
                <div className=" py-3 md:py-6 border-b pb-3 border-[#FFFFFF1A]">
                    <p className=" md:w-[280px] font-Anuphan text-xs font-normal">Choose your preferred identification document</p>
                    <select onChange={(e)=>{setidType(e.target.value)}} className=" bg-[#1e1e1e] font-Anuphan mt-2 rounded-[15px] p-2 w-[120px] border border-[#FFFFFF1A] h-[40px] font-medium text-sm">
                        <option value="ID">Identity Card</option>
                        <option value="DL">Driver's License</option>
                    </select>
                </div>
                <div className=" py-3 md:py-6 border-b border-[#FFFFFF1A] relative flex flex-col md:flex-row  items-start space-y-2 md:space-y-0">
                    <span className=" flex flex-col ">
                        <p className=" text-white font-Anuphan text-sm font-medium">Upload front side of your card</p>
                        <p className=" md:w-[280px] font-Anuphan text-xs font-normal">Verify your account by uploading a clear image of your document</p>
                    </span>
                    <div className=' w-full md:ml-[8%] md:pr-10'>
                        <div name="" className=" w-full flex flex-col justify-center items-center h-[126px] p-2.5 bg-[#121212] rounded-[8px] text-base font-normal font-Anuphan" onClick={handleFileInputClick1} onDragOver={handleDragOver} onDrop={handleDrop1} id="">
                            <input type="file" className="hidden" ref={fileInputRef1} onChange={handleFileInputChange1} />
                            <button className=" bg-[#000] w-[40px] h-[40px] rounded-[50%] flex justify-center items-center"><img src={ cloud } alt="" /></button>
                            <p className=" font-Anuphan font-normal text-sm"><span onClick={ handleFileInputClick1} className=" cursor-pointer text-[#2A49F9] font-semibold">Click to upload</span> or drag and drop</p>
                            {selectedFileName1 ? (
                                <p className=" capitalize font-Anuphan font-normal text-sm">File name: {selectedFileName1}</p>
                            ) : ''}
                        </div>
                        <div className=" py-3">
                            <button onClick={ sumbmitImg1 } className=" my-2 flex justify-center items-center ml-auto bg-[#2A49F9] rounded-[8px] px-6 text-white py-2">
                                    { loading1 === 'No' && 'Save'}
                                    { loading1 === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                            </button>
                        </div>
                        <p id='img1' className=' ml-auto w-full hidden text-left text-[8px] md:text-xs -mt-5 font-Nunito font-medium text-[#0CD16B]'>*Your image has been saved</p>
                    </div>
                </div>
                <div className=" py-3 md:py-6 border-b border-[#FFFFFF1A] relative flex flex-col md:flex-row  items-start space-y-2 md:space-y-0">
                    <span className=" flex flex-col ">
                    < p className=" text-white font-Anuphan text-sm font-medium">Upload back side of your card</p>
                    < p className=" md:w-[280px] font-Anuphan text-xs font-normal">Verify your account by uploading a clear image of your document</p>
                    </span>
                    <div className=' w-full md:ml-[8%] md:pr-10'>
                        <div name="" className=" w-full flex flex-col justify-center items-center h-[126px] p-2.5 bg-[#121212] rounded-[8px] text-base font-normal font-Anuphan" onClick={handleFileInputClick2} onDragOver={handleDragOver} onDrop={handleDrop2} id="">
                            <input type="file" className="hidden" ref={fileInputRef2} onChange={handleFileInputChange2} />
                            <button className=" bg-[#000] w-[40px] h-[40px] rounded-[50%] flex justify-center items-center"><img src={ cloud } alt="" /></button>
                            <p className=" font-Anuphan font-normal text-sm"><span onClick={ handleFileInputClick2} className=" cursor-pointer text-[#2A49F9] font-semibold">Click to upload</span> or drag and drop</p>
                            {selectedFileName2 ? (
                                <p className=" capitalize font-Anuphan font-normal text-sm">File name: {selectedFileName2}</p>
                            ) : ''}
                        </div>
                        <div className=" py-3">
                            <button onClick={ sumbmitImg2 } className=" my-2 flex justify-center items-center ml-auto bg-[#2A49F9] rounded-[8px] px-6 text-white py-2">
                                    { loading2 === 'No' && 'Save'}
                                    { loading2 === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                            </button>
                        </div>
                        <p id='img2' className=' ml-auto w-full hidden text-left text-[8px] md:text-xs -mt-5 font-Nunito font-medium text-[#0CD16B]'>*Your image has been saved</p>
                    </div>
                </div>
                <div className=" md:py-6 py-3">
                    <button onClick={ sumbmitImg } className=" w-full my-2 flex justify-center items-center ml-auto bg-[#2A49F9] rounded-[8px] px-6 text-white py-2">
                            { loading === 'No' && 'Verify'}
                            { loading === 'Yes' && <img className=" w-6 h-6" src={ load }/> }
                    </button>
                </div>
                <p id='confirm' className=' ml-auto w-full text-left hidden text-[8px] md:text-xs -mt-5 font-Nunito font-medium text-[#0CD16B]'>*Your account will be verified shortly</p>
            </div>}
            </div>
        </div>
     );
}
 
export default Profile;
<>
</>