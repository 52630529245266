import { useContext } from 'react';
import { PageContext, UserInfo } from '../contexts/pageContext';

const Headerdash = () => {

    const [page, setPage] = useContext(PageContext)
    const [userData, setUserData] = useContext(UserInfo);

    return (         
        <>
        <div className=" w-full md:w-full md:translate-x-[80px] lg:translate-x-0 lg:w-4/5 border-b py-5 px-5 md:px-10 top-0 right-0 border-[rgba(255,255,255,0.2)] -z-10 absolute flex flex-row justify-between items-center">
            <p className='font-Anuphan ml-8 md:ml-0 text-lg font-medium'>{page}</p>
            <p className=' font-Anuphan text-sm font-medium text-[#a7a7a7]'>Welcome {userData.name}</p>
        </div>
        </>
     );
}
 
export default Headerdash;