import udl from "../images/udl.png";
import Acct from "../images/cracct.png";
import Arr from "../images/arr.png";
import tag from "../images/tag.png";
import moon from "../images/moon.png";
import { motion } from "framer-motion";

const Started = () => {
  return (
    <>
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ type: "tween", duration: 0.5, delay: 0.3 }}
        className=" px-5 pb-10 md:px-12 md:pb-16 relative"
      >
        <img
          src={udl}
          className=" absolute bottom-[15%] lg:-bottom-[30%] left-0 -z-[99999] w-[250px] h-[250px] blur-2xl backdrop-blur-xl"
          alt=""
        />
        <p className=" text-xl md:text-3xl font-extrabold text-center font-Anuphan capitalize">
          We make Investing easy
        </p>
        <p className=" text-base font-thin tracking-wide mt-3 text-center font-Anuphan">
          Get started in 3 simple steps
        </p>
        <div className=" flex justify-between flex-col items-center lg:flex-row mt-11 space-y-8 lg:space-y-0">
          <div className=" px-8 py-5 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px] h-[340px]">
            <img src={Acct} className="" alt="" />
            <p className=" text-xl font-bold font-Anuphan mt-7">
              Step 1: Create Account
            </p>
            <p className=" text-[#777B83] md:w-[300px] font-normal tracking mt-6">
              Easily create your Bitstakepro account with a few steps
            </p>
            <button className=" p-2 rounded-lg bg-[#0046FF] font-Anuphan text-sm mt-11">
              Sign Up
            </button>
          </div>
          <div className=" px-8 py-5 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px] h-[340px]">
            <img src={tag} className="" alt="" />
            <p className=" text-xl font-bold font-Anuphan mt-7">
              Step 2: Fund your account
            </p>
            <p className=" text-[#777B83] md:w-[300px] font-normal tracking mt-6">
              Securely and conveniently fund your account using our variety of
              payment methods.
            </p>
            <span className="font-Anuphan text-sm mt-11 flex text-[#0046FF] items-center flex-row">
              Buy Now
              <img src={Arr} className=" mt-1 ml-1" alt="" />
            </span>
          </div>
          <div className=" px-8 py-5 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-[20px] h-[340px]">
            <img src={moon} className="" alt="" />
            <p className=" text-xl font-bold font-Anuphan mt-7">
              Step 3: Monitor your investments
            </p>
            <p className=" text-[#777B83] md:w-[300px] font-normal tracking mt-6">
              Stay on top of your investments and make informed decisions with
              our user-friendly tools and resources
            </p>
            <span className="font-Anuphan text-sm mt-11 flex text-[#0046FF] items-center flex-row">
              Start Now
              <img src={Arr} className=" mt-1 ml-1" alt="" />
            </span>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Started;
