import { Link } from "react-router-dom";
import menu from "../images/menu.png";
import logo from "../images/Logomark.svg";
import { useEffect } from "react";

const Navbar = () => {
  useEffect(() => {
    const overlay = document.getElementById("overlay");
    const menuBtn = document.getElementById("menuBtn");

    menuBtn.addEventListener("click", () => {
      if (overlay.classList.contains("-translate-y-[500px]")) {
        overlay.classList.remove("-translate-y-[500px]");
      } else if (!overlay.classList.contains("-translate-y-[500px]")) {
        overlay.classList.add("-translate-y-[500px]");
      }
    });
  }, []);

  return (
    <>
      <div className=" px-5 md:px-12 py-3 md:py-5 fixed top-0 left-0 w-full flex justify-between items-center z-[50] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px]">
        <span className=" flex flex-row justify-center space-x-2 items-center">
          <img src={logo} className=" w-8 h-8 md:w-10 md:h-10" alt="" />
          <Link to="/">
            <p className=" font-Bruno text-lg md:text-2xl font-medium text-white">
              Bitstakepro
            </p>
          </Link>
        </span>
        <span className=" font-Anuphan text-sm font-medium hidden  lg:flex space-x-6 items-center justify-center">
          <Link to="/">
            <p className=" tracking-wide">Home</p>
          </Link>
          <p className=" tracking-wide">Market</p>
          <Link to="/terms">
            <p className=" tracking-wide">Legal</p>
          </Link>
          <Link to="/contact">
            <p className=" tracking-wide">Contact us</p>
          </Link>
          <p className=" tracking-wide">About</p>
        </span>
        <span className="hidden lg:flex space-x-5 lg:space-x-8 font-Anuphan text-sm font-medium capitalize items-center">
          <Link to="/Signin">
            <p className="">Log in</p>
          </Link>
          <Link to="/Signup">
            <button className=" px-3 py-2 bg-[#2A49F9] text-white rounded-md">
              Get Started
            </button>
          </Link>
        </span>
        <img
          id="menuBtn"
          src={menu}
          className=" block lg:hidden w-6 h-6"
          alt=""
        />
      </div>
      <div
        id="overlay"
        className=" -mt-[15px] w-full bg-[rgba(78,78,97,0.2)] -translate-y-[500px] backdrop-blur-[15px] h-[400px] flex justify-center items-center transition-all duration-500 fixed z-[40]"
      >
        <div>
          <div className=" flex flex-col justify-center items-center space-y-5 -mt-14">
            <Link to="/">
              <p className=" font-Anuphan text-lg tracking-wide text-white font-medium">
                Home
              </p>
            </Link>
            <p className=" font-Anuphan text-lg tracking-wide text-white font-medium">
              Market
            </p>
            <Link to="/terms">
              <p className=" font-Anuphan text-lg tracking-wide text-white font-medium">
                Legal
              </p>
            </Link>
            <Link to="/contact">
              <p className=" font-Anuphan text-lg tracking-wide text-white font-medium">
                Contact
              </p>
            </Link>
            <p className=" font-Anuphan text-lg tracking-wide text-white font-medium">
              About
            </p>
          </div>
          <span className=" absolute bottom-5 flex flex-row space-x-3 -ml-[62px] font-medium">
            <Link to="/Signin">
              <button className=" px-3 py-2 text-center text-white font-Anuphan text-base rounded-md">
                Log-in
              </button>
            </Link>
            <Link to="/Signup">
              <button className=" px-3 py-2 text-center font-Anuphan text-base bg-[#2A49F9] rounded-md">
                Get Started
              </button>
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default Navbar;
