import jdl from '../images/jdl.png';
import StocksTab from './stocksTab';
import { motion } from 'framer-motion';

const Charts = () => {

    return ( 
        <>
        
        <motion.div 
        initial={{ opacity:0}}
        whileInView={{ opacity:1}}
        transition={{type:'tween', duration:1, delay:0.3}}
        className=" px-5 pb-10 md:px-12 md:pb-16 relative">
            <img src={ jdl } className=' absolute bottom-0  right-0 -z-[99999] w-[250px] h-[250px] blur-2xl backdrop-blur-xl' alt="" />
            <p className=" font-bold font-Anuphan text-xl md:text-2xl lg:text-3xl ">Charts</p>
            <div className=' w-full h-[450px] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] mt-5 rounded-[20px]'>
                <StocksTab/>
            </div>
        </motion.div>
        </>
     );
}
 
export default Charts;