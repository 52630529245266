import  { useRef, useEffect } from 'react';

function OverviewCrypto() {
  const tradingviewRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      "colorTheme": "dark",
      "dateRange": "12M",
      "showChart": true,
      "locale": "en",
      "width": "100%",
      "height": "100%",
      "largeChartUrl": "",
      "isTransparent": true,
      "showSymbolLogo": true,
      "showFloatingTooltip": false,
      "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
      "plotLineColorFalling": "rgba(41, 98, 255, 1)",
      "gridLineColor": "rgba(240, 243, 250, 0)",
      "scaleFontColor": "rgba(106, 109, 120, 1)",
      "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
      "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
      "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
      "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
      "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
      "tabs": [
        {
          "title": "Crypto",
          "symbols": [
            {
              "s": "BINANCE:BTCUSDT"
            },
            {
              "s": "BINANCE:ETHUSDT"
            },
            {
              "s": "BITSTAMP:ETHUSD"
            },
            {
              "s": "BINANCE:SOLUSDT"
            },
            {
              "s": "BINANCE:XRPUSDT"
            },
            {
              "s": "BINANCE:DOGEUSDT"
            },
            {
              "s": "CRYPTOCAP:TOTAL"
            }
          ]
        }
      ]
    });

    tradingviewRef.current.appendChild(script);
    return () => {
      tradingviewRef.current.removeChild(script);
    }
  }, []);

  return (
    <div className="tradingview-widget-container" ref={tradingviewRef}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default OverviewCrypto;
