import step from "../images/step.png";
import div from "../images/div.png";
import bigmoon from "../images/bigmoon.png";
import jdl from "../images/jdl.png";
import { motion } from "framer-motion";

const Offer = () => {
  return (
    <>
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ type: "tween", duration: 0.5, delay: 0.3 }}
        className=" px-5 pb-10 md:px-12 md:pb-16 relative"
      >
        <img
          src={jdl}
          className=" absolute bottom-0 md:-bottom-[7%] right-0 -z-[99999] w-[250px] h-[250px] blur-2xl backdrop-blur-xl"
          alt=""
        />
        <p className=" text-2xl md:text-3xl font-extrabold text-center font-Anuphan capitalize">
          What we offer
        </p>
        <div className=" flex w-full lg:flex-row flex-col mt-8 lg:mt-[100px] relative">
          <div className="">
            <p className=" text-left text-xl md:text-3xl md:w-[750px] font-bold font-Anuphan capitalize">
              Bitstakepro's Trading System Makes You Able To Achieve Maximum
              Profit
            </p>
            <p className=" md:w-[650px] mt-3 md:mt-7 text-[#777B83] text-base ">
              The system of indicators and analysis of inter-stocks spreads
              allows developing effective channel strategies, and automating the
              trading process through the Arbinox Trading Bot to execute a
              stable profit for the entire time.
            </p>
          </div>
          <img
            src={step}
            className=" right-0 lg:absolute w-[132px] h-[140px] md:w-[192px] md:h-[200px] block ml-auto top-0"
            alt=""
          />
        </div>
        <div className=" flex w-full lg:flex-row flex-col mt-8 md:mt-[70px] lg:mt-[100px] relative">
          <div className=" block ml-auto">
            <p className="text-right text-xl md:text-3xl md:w-[750px] font-bold font-Anuphan capitalize">
              Diversified Investment Products
            </p>
            <p className="text-right mt-3 md:mt-7 md:w-[750px] text-[#777B83] text-base ">
              Bitstakepro allows you access to a wide range of investment
              products, including stocks, bonds, mutual funds, options, futures,
              forex, and cryptocurrencies.
            </p>
          </div>
          <img
            src={div}
            className=" left-0 lg:absolute w-[171px] mt-2 h-[140px] lg:w-[231px] lg:h-[200px] top-0"
            alt=""
          />
        </div>
        <div className=" flex w-full flex-col lg:flex-row mt-8 md:mt-[70px] lg:mt-[125px] relative">
          <div className="">
            <p className=" text-left text-xl md:text-3xl md:w-[750px]  font-bold font-Anuphan capitalize">
              Security and Protection
            </p>
            <p className=" md:w-[650px] mt-3 md:mt-7 text-[#777B83] text-base ">
              We are regulated by financial authorities and adhere to strict
              security and privacy standards to protect your assets and personal
              information.
            </p>
          </div>
          <img
            src={bigmoon}
            className=" block ml-auto right-0 lg:absolute w-[94px] h-[140px] mt-2 md:w-[154px] md:h-[200px] top-0"
            alt=""
          />
        </div>
      </motion.div>
    </>
  );
};

export default Offer;
