import React, { useEffect, useRef } from 'react';

function ForexAnalysis() {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
    script.innerHTML = JSON.stringify({
      "interval": "1m",
      "width": "100%",
      "isTransparent": true,
      "height": "100%",
      "symbol": "FX:EURUSD",
      "showIntervalTabs": true,
      "locale": "en",
      "colorTheme": "dark"
    });

    const container = widgetContainerRef.current;

    // Remove existing TradingView widget
    container.innerHTML = '';

    // Add new TradingView widget
    container.appendChild(script);

    return () => {
      container.innerHTML = '';
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={widgetContainerRef}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}
export default ForexAnalysis;
