import { useEffect, useRef } from 'react';

const StockWidget = () => {
  const tvWidgetContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      "symbol": "NASDAQ:AAPL",
      "width": "100%",
      "colorTheme": "dark",
      "isTransparent": true,
      "locale": "en"
    });

    tvWidgetContainerRef.current.appendChild(script);

    return () => {
      // cleanup: remove the TradingView widget from the DOM
      const tvWidget = window.tvWidget;
      if (tvWidget) {
        tvWidget.remove();
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={tvWidgetContainerRef}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default StockWidget;
